// import React from 'react'
// import { NavLink } from 'react-router-dom';


// const Navbar = () => {
//   return (
//     <>
//       <div className="container-fluid bg-primary px-5 d-none d-lg-block">
//         <div className="row gx-0">
//           <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
//             <div className="d-inline-flex align-items-center" style={{ height: '45px' }}>
//               <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href><i className="fab fa-facebook-f fw-normal" /></a>
//               <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href><i className="fab fa-instagram fw-normal" /></a>
//               <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle" href><i className="fab fa-youtube fw-normal" /></a>
//             </div>
//           </div>
//           <div className="col-lg-4 text-center text-lg-end">
//             <div className="d-inline-flex align-items-center" style={{ height: '45px', marginLeft: '-200px' }}>
//               <a href="#"><small className="me-3 text-light"><i className="fa fa-envelope me-2" />jainboardingsangli@gmail.com</small></a>
//               <a href="#"><small className="me-3 text-light"><i className="fa fa-phone me-2" />0233 2623206</small></a>

//               <div className="nav-item dropdown">
//                 <NavLink to="/StudentLogin" className="nav-link dropdown-toggle" style={{ color: 'white' }} data-bs-toggle="dropdown" activeClassName="active">
//                   <i className="fa fa-user me-2" />Login
//                 </NavLink>
//                 <div className="dropdown-menu m-0" style={{ padding: '10px', width: '200px', height: '160px' }}>
//                   <h6>Hello Users,</h6>
//                   <span>Please Login your Account here</span>
//                   <br />
//                   <br />
//                   <button className="btn btn-primary" style={{ marginLeft: '50px' }}>Login</button>
//                 </div>
//               </div>


//             </div>



//           </div>
//         </div>
//       </div>
//       {/* Topbar End */}
//       {/* Navbar & Hero Start */}
//       <div className="container-fluid position-relative p-0">
//         <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0 ">
//           <a href className="navbar-brand p-0">
//             <h6 className="m-0"><img src="assets/img/jain logo.png" alt="Logo" /></h6>
//           </a>
//           <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
//             <span className="fa fa-bars" />
//           </button>
//           <div className="collapse navbar-collapse" id="navbarCollapse">
//             <div className="navbar-nav ms-auto py-0">

//               <NavLink to="/Home" className="nav-item nav-link" activeClassName="active">Home</NavLink>
//               <NavLink to="/About" className="nav-item nav-link" activeClassName="active">About</NavLink>
//               <NavLink to="/Services" className="nav-item nav-link" activeClassName="active">Services</NavLink>
//               <NavLink to="/Boarding" className="nav-item nav-link" activeClassName="active">Boarding</NavLink>
//               <div className="nav-item dropdown">
//                 <NavLink to="/Gallery" className="nav-link dropdown-toggle" style={{ color: 'white' }} data-bs-toggle="dropdown" activeClassName="active">
//                   Gallery
//                 </NavLink>
//                 <div className="dropdown-menu m-0">
//                   <NavLink to="/Gallery" className="dropdown-item" activeClassName="active">Photo</NavLink>
//                   <NavLink to="/Boardofdir" className="dropdown-item" activeClassName="active">Board of Directors</NavLink>
//                 </div>
//               </div>
//               <NavLink to="/Alumini" className="nav-item nav-link" activeClassName="active">Alumini</NavLink>
//               <NavLink
//                to="https://registration.jainboardingsangli.com" 
//                className="nav-item nav-link" activeClassName="active">VadhuVar</NavLink>
//               <NavLink to="/Blog" className="nav-item nav-link" activeClassName="active">Jobs</NavLink>

//               <NavLink to="/Contact" className="nav-item nav-link" activeClassName="active">Contact</NavLink>
//             </div>
//           </div>
//         </nav>
      
//       </div> 
//     </>
//   )
// }

// export default Navbar




import React, { useEffect, useState } from 'react';
import { Menu, Drawer, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { HighlightMasterFindAll } from '../../ApiFile';
import axios from 'axios';

const Navbar = () => {
  const [visible, setVisible] = useState(false);

  const [texts, setTexts] = useState([]);

  useEffect(() => {
    // Fetch the data from the API
    axios.get(HighlightMasterFindAll)
      .then((response) => {
        const fetchedTexts = response.data.map(item => item.admin_text);
        setTexts(fetchedTexts);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".navbar");
      if (window.scrollY > 100) {
        navbar.classList.add("fixed-top", "fixed-background");
      } else {
        navbar.classList.remove("fixed-top", "fixed-background");
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const marqueeStyle = {
    backgroundColor: "rgb(238, 21, 21)",  
    padding: "2px",       
    fontSize: "20px",   
    fontWeight: "bold",     
    display: "inline-block",  
    color: "yellow",          
    textShadow: "2px 2px 4px #000000",
    animation: "blink 1s step-start infinite",
  };
  return (
    <>
      <div className="container-fluid bg-primary px-5 d-none d-lg-block">
        <div className="row gx-0">
          <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
            <div className="d-inline-flex align-items-center" style={{ height: '45px' }}>
              <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href="https://www.facebook.com"><i className="fab fa-facebook-f fw-normal" /></a>
              <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href="https://www.instagram.com"><i className="fab fa-instagram fw-normal" /></a>
              <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle" href="https://www.youtube.com"><i className="fab fa-youtube fw-normal" /></a>
            </div>
          </div>
          <div className="col-lg-4 text-center text-lg-end">
            <div className="d-inline-flex align-items-center" style={{ height: '45px', marginLeft: '-200px' }}>
              <a href="mailto:jainboardingsangli@gmail.com"><small className="me-3 text-light"><i className="fa fa-envelope me-2" />jainboardingsangli@gmail.com</small></a>
              <a href="tel:02332623206"><small className="me-3 text-light"><i className="fa fa-phone me-2" />0233 2623206</small></a>
              <div className="nav-item dropdown">
                <NavLink to="/StudentLogin" className="nav-link dropdown-toggle" style={{ color: 'white' }} data-bs-toggle="dropdown" isActive={() => window.location.pathname === '/StudentLogin'}>
                  <i className="fa fa-user me-2" />Student Login 
                </NavLink>
                <div className="dropdown-menu m-0" style={{ padding: '10px', width: '200px', height: '160px' }}>
                  <h6>Hello Users,</h6>
                  <span>Please Login your Account here</span>
                  <br />
                  <br />
                  <button className="btn btn-primary" style={{ marginLeft: '50px' }}>Login</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "rgb(238, 21, 21)" }} sm={12} md={12}>
      <marquee>
        <span style={marqueeStyle}>
          {texts.join(" | ")}
        </span>
      </marquee>
    </div>
      
      <div className="container-fluid position-relative p-0">
        <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
          <a href="/" className="navbar-brand p-0">
            <h6 className="m-0"><img src="assets/img/jain logo.png" alt="Logo" /></h6>
          </a>
          <Button className="navbar-toggler d-lg-none" type="button"  onClick={showDrawer}>
            <MenuOutlined />
          </Button>
          <div className="collapse navbar-collapse d-none d-lg-block" id="navbarCollapse" style={{display:"flex",justifyContent:"end"}}>
            <Menu mode="horizontal" >
              <Menu.Item key="1">
                <NavLink to="/Home">Home</NavLink>
              </Menu.Item>
              <Menu.Item key="2">
                <NavLink to="/About">About</NavLink>
              </Menu.Item>
              <Menu.Item key="3">
                <NavLink to="/Services">Services</NavLink>
              </Menu.Item>
              <Menu.Item key="4">
                <NavLink to="/StudentLogin">Hostel</NavLink>
              </Menu.Item>
              <Menu.SubMenu key="sub1" title="Gallery">
                <Menu.Item key="5">
                  <NavLink to="/Gallery">Photo</NavLink>
                </Menu.Item>
                <Menu.Item key="6">
                  <NavLink to="/Boardofdir">Board of Directors</NavLink>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.Item key="7">
                <NavLink to="/Alumini">Alumini</NavLink>
              </Menu.Item>
              <Menu.Item key="8">
                <NavLink to="https://registration.jainboardingsangli.com">VadhuVar</NavLink>
              </Menu.Item>
              <Menu.Item key="9">
                <NavLink to="/Donation">Donation</NavLink>
              </Menu.Item>
              <Menu.Item key="10">
                <NavLink to="/Contact">Contact</NavLink>
              </Menu.Item>
          

              <Menu.SubMenu key="sub2" title="Admin">
                <Menu.Item key="11">
                  <NavLink to="/AdminLogin">Main</NavLink>
                </Menu.Item>
                <Menu.Item key="12">
                  <NavLink 
                  to="/OfficeAttendant"
                  >Office Attendant</NavLink>
                </Menu.Item>
                    <Menu.Item key="13">
                  <NavLink 
                  to="/Supertendent"
                  >Superintendent</NavLink>
                </Menu.Item>
                <Menu.Item key="14">
                  <NavLink 
                  to="/MessAttendant"
                  >Mess Attendant</NavLink>
                </Menu.Item>
                <Menu.Item key="15">
                  <NavLink 
                  to="https://registration.jainboardingsangli.com/#/admin"
                  >Vadhuvar Admin</NavLink>
                </Menu.Item>
              </Menu.SubMenu>
            </Menu>
          </div>
          <Drawer title="Menu" placement="left" onClose={onClose} visible={visible} className="d-lg-none">
            <Menu mode="vertical">
              <Menu.Item key="1">
                <NavLink to="/Home">Home</NavLink>
              </Menu.Item>
              <Menu.Item key="2">
                <NavLink to="/About">About</NavLink>
              </Menu.Item>
              <Menu.Item key="3">
                <NavLink to="/Services">Services</NavLink>
              </Menu.Item>
              <Menu.Item key="4">
                <NavLink to="/StudentLogin">Hostel</NavLink>
              </Menu.Item>
              <Menu.SubMenu key="sub1" title="Gallery">
                <Menu.Item key="5">
                  <NavLink to="/Gallery">Photo</NavLink>
                </Menu.Item>
                <Menu.Item key="6">
                  <NavLink to="/Boardofdir">Board of Directors</NavLink>
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.Item key="7">
                <NavLink to="/Alumini">Alumini</NavLink>
              </Menu.Item>
              <Menu.Item key="8">
                <NavLink to="https://registration.jainboardingsangli.com">VadhuVar</NavLink>
              </Menu.Item>
              <Menu.Item key="9">
                <NavLink to="/Donation">Donation</NavLink>
              </Menu.Item>
              <Menu.Item key="10">
                <NavLink to="/Contact">Contact</NavLink>
              </Menu.Item>
              <Menu.SubMenu key="sub2" title="Admin">
                <Menu.Item key="11">
                  <NavLink to="/AdminLogin">Main</NavLink>
                </Menu.Item>
                <Menu.Item key="12">
                  <NavLink 
                  to="/OfficeAttendant"
                  >Office Attendant</NavLink>
                </Menu.Item>
                    <Menu.Item key="13">
                  <NavLink 
                  to="/Supertendent"
                  >Superintendent</NavLink>
                </Menu.Item>
                <Menu.Item key="14">
                  <NavLink 
                  to="/MessAttendant"
                  >Mess Attendant</NavLink>
                </Menu.Item>
                <Menu.Item key="15">
                  <NavLink 
                  to="https://registration.jainboardingsangli.com/#/admin"
                  >Vadhuvar Admin</NavLink>
                </Menu.Item>
              </Menu.SubMenu>
            </Menu>
          </Drawer>
        </nav>
      </div> 
    </>
  );

  
};

const blinkKeyframes = `
@keyframes blink {
  50% { opacity: 0; }
}
`;

// Inject the keyframes into the document head
const styleSheet = document.styleSheets[0];
styleSheet.insertRule(blinkKeyframes, styleSheet.cssRules.length);

export default Navbar;



