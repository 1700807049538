import React, { useState, useEffect } from 'react';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import { MDBDataTable } from 'mdbreact';
import { ToDoFindOne } from '../../ApiFile';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ToDoAllAdmin = () => {
  const [ToDo, setToDo] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    let flag =  sessionStorage.getItem('flag');
if( !flag){
  navigate('/')
}
  
  }, [])

  let flag = Number(sessionStorage.getItem("flag"));

  useEffect(() => {
    const fetchToDo = async () => {
      try {
        const response = await fetch(`${ToDoFindOne}/${flag}`);
        if (response.ok) {
          const data = await response.json();
          setToDo(data);
        } else {
          console.error('Error fetching To Do: Response not ok');
        }
      } catch (error) {
        console.error('Error fetching ToDo:', error);
      }
    };

    fetchToDo();
  }, []);

  const tableData = {
    columns: [
      {
        label: 'Sr.No',
        field: 'sr_no',
        sort: 'asc',
      },
      {
        label: 'Description',
        field: 'description',
        sort: 'asc',
      },
     
      {
        label: 'Completed By Date',
        field: 'completed_by_date',
        sort: 'asc',
      },
      {
        label: 'Created By',
        field: 'createdby',
        sort: 'asc',
      },
    ],
    rows: ToDo.map((suggestion, index) => ({
        sr_no:index + 1,
      description: suggestion.description,
      completed_by_date: suggestion.completed_by_date,
      createdby: suggestion.createdby,
    })),
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <NavLogo />
      
        <div className="block-header">
          <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
          Task To Do 
          </h2>
        </div>

        
        <Row className="m-0 p-0 px-md-3 py-1 pb-5 mb-5 mt-4 w-100" style={{ display: 'flex' }}>
          <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
            <div style={{ overflowY: 'auto' }}>
              <div style={{ overflowX: 'auto' }}>
                <MDBDataTable striped className="custom-datatable text-center" bordered hover small data={tableData} />
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default ToDoAllAdmin;
