import react, { Fragment, useState, useEffect } from "react";
// import "./Form.css";
// import "./Formm.js";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, Navigate, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { FaCalendarAlt } from "react-icons/fa";
import dayjs from "dayjs";
import html2pdf from "html2pdf.js";
import $ from "jquery";
import SideNavbarStudentReg from "./SideNavbarStudentReg";
import NavBarStudentReg from "./NavBarStudentReg";
import afterlogo from './login-after.png';
import qrcode from './JainBoardingQr.jpeg';
import './SideNavbarStudentReg.css'
import { Col, Form, FormControl, Row, Spinner } from "react-bootstrap";
import { Button, DatePicker } from 'antd';

import logo from '../studentDashboard/img/logo2.png';
import logo2 from '../studentDashboard/img/logonew.jpg';
import logo3 from '../studentDashboard/img/StudentPic.jpg';
import { DistrictFindAllApi, DocumentAddApi, DocumentFindOneApi, RegristrationAddApi, StateFindAllApi, SubCateroryFindAllApi, TalukaFindAllApi, TermFindAllApi } from "../ApiFile";
// Register fonts
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const toDataURL = url =>
  fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    }));

const RegForm = () => {
  const [date, setDate] = useState();
  const formattedDate = dayjs(date).format("DD/MM/YYYY");

  const [Sfname, setSfname] = useState("");
  const [Smname, setSmname] = useState("");
  const [Slname, setSlname] = useState("");
  // const [Ffname, setFfname] = useState("");
  const [Fmname, setFmname] = useState("");
  // const [Flname, setFlname] = useState("");

  const [Village, setVillage] = useState("");
  const [House, setHouse] = useState("");
  const [Pin, setPin] = useState("");
  const [SubCategory, setSubCategory] = useState("");

  const [Smobile, setSmobile] = useState("");
  const [Pmobile, setPmobile] = useState("");
  const [age, setAge] = useState("");
  const [FOccupation, setFOccupation] = useState("");
  const [email, setEmail] = useState("");

  const [PCollageName, setPCollageName] = useState("");
  const [StdDiv, setStdDiv] = useState("");
  const [NyearcollageName, setNyearcollageName] = useState("");
  const [CCollageName, setCCollageName] = useState("");
  const [Profeciency, setProfeciency] = useState([]);
  const [Fellowship, setFellowship] = useState([]);

  const [PHostelAddress, setPHostelAddress] = useState("");
  const [PHostelName, setPHostelName] = useState("");
  const [Duration, setDuration] = useState("");

  const [adharCard, setAdharCard] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [markList, setMarkList] = useState(null);
  const [proficiencyDoc, setProficiencyDoc] = useState(null);
  const [tax, setTax] = useState(null);
  const [taxId, setTaxid] = useState("");
  const [paymentMode, setPaymentMode] = useState("");

  const [stateList, setStateList] = useState([]);
  const [rules, setRules] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [talukaList, setTalukaList] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedTaluka, setSelectedTaluka] = useState("");

  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [inputValid, setInputValid] = useState(false);

  const [errors, setErrors] = useState({}); // Define errors state variable
  const [taxidError, setTaxidError] = useState("");
  const [paymentModeError, setpaymentModeError] = useState("");
  const [taxError, setTaxError] = useState("");
  const navigate = useNavigate();

  const [logoBase64, setLogoBase64] = useState(null);
  const [logo2Base64, setLogo2Base64] = useState(null);
  const [logo3Base64, setLogo3Base64] = useState(null);

  useEffect(() => {
    fetchRuleData();
  }, []);

  const fetchRuleData = async () => {
    try {
      const response = await fetch(TermFindAllApi);
      if (!response.ok) {
        throw new Error('Failed to fetch block data');
      }
      const data = await response.json();
      if (data === "NOTFOUND") {
        console.log("if ");
      setRules([]); // Set rules to an empty array if "NOTFOUND"
    } else {
      console.log("else ");
      setRules(data);
    }
     
      // console.log("rule api res...",data)
    } catch (error) {
      // console.error('Error fetching block data:', error);
    }
  };

  useEffect(() => {
    // Fetch the list of states from the API
    axios
      .get(StateFindAllApi)
      .then((response) => {
        setStateList(response.data);
      })
      .catch((error) => {
        // console.error("Error fetching state list:", error);
      });
  }, []);

  useEffect(() => {

    // console.log(selectedState);
    if (selectedState !== "") {
      axios
        .get(`${DistrictFindAllApi}/${selectedState}`)
        .then((response) => {
          setDistrictList(response.data); 
        })
        .catch((error) => {
          // console.error("Error fetching district list:", error);
        });
    }
  }, [selectedState]);

  useEffect(() => {
    // console.log(selectedDistrict);

    // Fetch the list of talukas based on the selected state and district
    if (selectedState !== "" && selectedDistrict !== "") {
      axios
        .get(
          `${TalukaFindAllApi}/${selectedState}/${selectedDistrict}`
        )
        .then((response) => {
          setTalukaList(response.data);
        })
        .catch((error) => {
          // console.error("Error fetching taluka list:", error);
        });
    }
  }, [selectedState, selectedDistrict]);

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
    setSelectedDistrict("");
    setSelectedTaluka("");
  };

  const downloadQRCode = () => {
    const link = document.createElement('a');
    link.href = qrcode;
    link.download = <img src={qrcode} className='w-80 text-center px-md-3 px-1 mb-3 mb-md-0' height={250} />;
    link.click();
  };

  const handleDistrictChange = (e) => {
    setSelectedDistrict(e.target.value);
    setSelectedTaluka("");
  };

  const handleTalukaChange = (e) => {
    setSelectedTaluka(e.target.value);
  };
  const handleFileChange = (e, setter) => {
    setter(e.target.files[0]);
  };
  const validateInputs = () => {
    const newErrors = {};

    if (!PCollageName.trim()) {
      newErrors.PCollageName = "Last year school/college name is necessary";
    }

    if (!StdDiv.trim()) {
      newErrors.StdDiv = "Standard and Division is necessary";
    }

    if (!CCollageName.trim()) {
      newErrors.CCollageName = " Current School/College Name is necessary";
    }

    if (!NyearcollageName.trim()) {
      newErrors.NyearcollageName = "Standard and Division is necessary";
    }

    setErrors(newErrors);

    // If there are no errors, return true, else return false
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    fetchSubCategories();
  }, []); // Fetch subcategories when the component mounts

  // Define the function to fetch subcategories
  function fetchSubCategories() {
    fetch(SubCateroryFindAllApi)
      .then((response) => response.json())
      .then((data) => {
        setSubCategories(data);
      })
      .catch((error) => {
        // console.error("Error fetching subcategories:", error);
        setError(true);
      });
  }

  const handleSubmit = (event) => {
    const isValid = validateInputs();

    if (isValid) {
      goToNextPage();
    } else {
      // console.log("Form validation failed. Please check the errors.");
    }

    event.preventDefault();

    const Obj = {
      s_fname: Sfname,
      s_mname: Smname,
      s_lname: Slname,
      father_fname: Smname,
      father_mname: Fmname,
      father_lname: Slname,

      village: Village,
      pincode: Pin,
      address: House,
      state: selectedState,
      district: selectedDistrict,
      taluka: selectedTaluka,

      mobile_no: Smobile,
      parents_mobno: Pmobile,
      sub_category: selectedSubCategory,
      dateofbirth: formattedDate,
      age: age,
      father_occupation: FOccupation,
      email: email,
      nextyearcollagename: NyearcollageName,
      l_collagename: PCollageName,
      std: StdDiv,
      collage_name: CCollageName,
      profeciency: Profeciency,
      fellowship: Fellowship,
    };

    // console.log("api data console.........", Obj);
    axios
      .post(RegristrationAddApi, Obj)
      .then((response) => {
        // console.log("Success re/.:", response);
        // console.log("Success:", response.data);
        if(response.data == "ALREADYEXIST"){
       
          alert('Email id already registered. Try with another email ..!!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true, 
            draggable: true,
          });
          navigate('/StudentLogin')
        }
       else if (response.data.message === "SAVED" && response.data.regId) {
          // Save registration ID in session storage
          sessionStorage.setItem("registrationId", response.data.regId);
          // console.log("reg id....",response.data.regId)
       
        
        }
        else if(response.data === "ERROR"){
toast.error("Error Occured!!! Please Fill Again")
navigate('/RegForm')
        } else {

          // console.error("Unexpected response:", response.data);
        }
      })
      .catch((error) => {
        // console.error("Error:", error);
      });
    // documents
    const handleChange = (e, setter) => {
      const file = e.target.files[0];
      setter(file);
    };
  };
  const handleTaxIdChange = (value) => {
    setTaxid(value);

    // Validate taxId
    if (!value) {
      setTaxidError("Transaction ID is required");
    } else {
      setTaxidError("");
    }
  };

  // Function to handle input change for taxScreenshot
  const handleTaxScreenshotChange = (file) => {
    setTax(file);

    // Validate taxScreenshot
    if (!file) {
      setTaxError("Screenshot is required");
    } else {
      setTaxError("");
    }
  };

  const handleSubmitUpload = () => {
    const regId = sessionStorage.getItem("registrationId");
    // console.log("reg id=====",regId);

    goToNextPage();
    if (!regId) {
      // console.error("Registration ID not found in session storage");
      return; // Exit function if regId is not available
    }
    if (!taxId) {
      setTaxidError("Transaction ID is required");
      return;
    }
    if (!paymentMode) {
      setpaymentModeError("Pay Mode is required");
      return;
    }

    // Validate taxScreenshot
    if (!tax) {
      setTaxError("Screenshot is required");
      return;
    }

    const formData = new FormData();
    formData.append("files", tax);
    formData.append("files", photo);
    formData.append("files", adharCard);
    formData.append("files", markList);
    formData.append("files", proficiencyDoc);
    formData.append("taxId", taxId);
    formData.append("pay_mode", paymentMode);
    formData.append("reg_id", regId);
    // console.log("FormData:", formData);

    axios
      .post(
        `${DocumentAddApi}/${regId}`, // Use the regId from session storage in the URL
        formData
      )
      .then((response) => {
        // console.log("RESPONSE REG ID,,,,,,,,,", regId);
      })
      .catch((error) => {
        // Handle upload error
        // console.error("Error uploading files:", error);
      });
  };

  function validateMobileNumber() {
    const mobileNumber = document.getElementById("mobileNumber").value;
    if (mobileNumber.length !== 10 || isNaN(mobileNumber)) {
      alert("");
    }
  }

  const [data, setData] = useState([]);

const generatePDF = async () => {
  setLoading(true); // Show loader

  const regId = sessionStorage.getItem("registrationId");
  if (!regId) {
    // console.error("Registration ID is missing");
    setLoading(false);
    return;
  }

  try {
    const [logoBase64, logo2Base64 ,logo3Base64] = await Promise.all([toDataURL(logo), toDataURL(logo2), toDataURL(logo3)]);
    setLogoBase64(logoBase64);
    setLogo2Base64(logo2Base64);
    setLogo3Base64(logo3Base64);

    const res = await axios.get(`${DocumentFindOneApi}/${regId}`);
    const data = res.data;

    const documentDefinition = {
      content: [
        {
          columns: [
            { style: "logo", width: "15%", image: logoBase64, fit: [50, 50] },
            {
              width: "70%",
              text: [
                { text: "Dakshin Bharat Jain Sabha's \n", style: "header1" },
                { text: "Sheth R D Dawada Digambar Jain Boarding,\n", style: "header" },
                { text: "37, Mahavir Nagar, Sangli 416416, Phone: 0233-262306\n", style: "header2" },
                { text: "Web : www.jainboardingsangli.com    Email : jainboardingsangli@gmail.com \n", style: "header3" },
              ],
            },
            { style: "logo", width: "15%", image: logo2Base64, fit: [50, 50] },
          ],
        },
        {
          margin: [0, 15, 0, 0],
          canvas: [
            {
              type: 'line',
              x1: 0, y1: 0,
              x2: 515, y2: 0,
              lineWidth: 1
            }
          ],
        },
        { text: 'Admission Application', style: 'title', alignment: 'center', margin: [0, 10, 0, 0] },
        { text: `Form No. : ${data.reg_id}`, style: 'formNo', alignment: 'left', margin: [0, -15, 0, 0] },
        { text: "Hon'ble Secretary/Superintendent", style: 'subheader', margin: [0, 15, 0, 0] },
        {
          text: `Sheth R D Dawada Digambar Jain Boarding, Sangli
I, the undersigned, request you to grant me admission in 'Sangli Jain Boarding'.
I have read the Boarding & Mess rules, agree to them and will strictly follow them .`,
    
        },
        // {
        //   text: `Photo`, style: 'photo' ,alignment: 'right', margin: [0, -45, 40, 40] 
    
        // },
        {
          columns: [
            {
              width: '*',
              text: '', // This is an empty column to push the photo to the right
            },
            {
              width: 'auto',
              stack: [
                { text: 'Photo', style: 'photo', alignment: 'center', margin: [0, -15, 0, 0] },
                {
                  image: logo3Base64, // Replace with your actual base64 image
                  fit: [100, 100],
                  border: [true, true, true, true], // Apply border on all sides
                  borderColor: 'gray',
                  margin: [0, -100, 0, 0] // Optional margin around the image
                }
              ]
            }
          ]
        },
        {
          table: {
            widths: ['*'],
            body: [
              [{ text: 'Personal Information', style: 'tableHeader', colSpan: 1, alignment: 'center' }],
              [
                {
                  text: [
                    { text: "Student's Full Name: ", bold: true }, `${data.name}\n`,
                    { text: "Father's Full Name: ", bold: true }, `${data.fatherName}\n`,
                    { text: "Address: ", bold: true }, `${data.address}\n`,
                    { text: "Contact No.: ", bold: true }, `${data.mob_no}\n`,
                    { text: "Parent's Contact No.: ", bold: true }, `${data.parents_mobno}\n`,
                    { text: "Birthdate: ", bold: true }, `${data.dateofbirth}      `,
                    { text: "Age: ", bold: true }, `${data.age}\n`,
                    { text: "Student's Sub-Cast: ", bold: true }, `${data.sub_category}\n`,
                    { text: "Father's Occupation: ", bold: true }, `${data.father_occupation}\n`,
                    { text: "Email Id: ", bold: true }, `${data.email}\n`
                  ],
                  margin: [0, 7, 0, 0],
                  lineHeight: 1.5
                }
              ]
            ]
          },
          layout: 'noBorders',
          margin: [0, 10, 0, 0]
        },
        {
          table: {
            widths: ['*'],
            body: [
              [{ text: 'Educational Information', style: 'tableHeader', colSpan: 1, alignment: 'center' }],
              [
                {
                  text: [
                    { text: "Last year School/college Name: ", bold: true }, `${data.l_collagename}\n`,
                    { text: "Standard and Division: ", bold: true }, `${data.std}\n`,
                    { text: "Current School/College Name: ", bold: true }, `${data.collage_name}\n`,
                    { text: "Current Standard and Division: ", bold: true }, `${data.nextyearcollagename}\n`,
                    { text: "Proficiency has been Achieved?: ", bold: true }, `${data.profeciency}\n`,
                    { text: "Have you received a Dakshin Bharat Jain Sabha's Scholarship ?: ", bold: true }, `${data.fellowship}\n`
                  ],
                  margin: [0, 7, 0, 0],
                  lineHeight: 1.5
                }
              ]
            ]
          },
          layout: 'noBorders',
          margin: [0, 10, 0, 0]
        },
        {
          table: {
            widths: ['*'],
            body: [
              [{ text: 'Document Uploaded', style: 'tableHeader', colSpan: 1, alignment: 'center' }],
              [
                {
                  text: [
                    { text: "Student's Aadhar Card: ", bold: true }, "Yes\n",
                    { text: "Student's Last year Marksheet: ", bold: true }, "Yes\n",
                    { text: "Current Year Admission Receipt: ", bold: true }, "Yes\n"
                  ],
                  margin: [0, 7, 0, 0],
                  lineHeight: 1.5
                }
              ]
            ]
          },
          layout: 'noBorders',
          margin: [0, 10, 0, 0]
        },
        { text: 'Date:', margin: [0, 20, 0, 0] },
        { text: "Student's signature", alignment: 'right', margin: [0, -14, 0, 0] }
      ],
      styles: {
        header: {
          fontSize: 18,
          alignment: "center",
          bold: true,
        },
        formNo: {
          fontSize: 14,
          bold: true,
        },
        header1: {
          fontSize: 15,
          alignment: "center",
        },
        header2: {
          fontSize: 13,
          alignment: "center",
        },
        header3: {
          fontSize: 9,
          alignment: "center",
        },
        logo: {
          alignment: "center",
        },
        photo: {
          border:"2px solid gray",
          height:100,
          width:100,
        },
        subheader: { fontSize: 14, bold: true },
        title: { fontSize: 18, bold: true },
        tableHeader: { fontSize: 18, bold: true, fillColor: '#7FB7B5', color: 'black' }
      }
    };

    pdfMake.createPdf(documentDefinition).open(); // Open the PDF in a new tab
    setLoading(false); 
    navigate('/');
  } catch (error) {
    setLoading(false); // Hide loader in case of an error
    // console.error("Error fetching data or generating PDF:", error); 
  }
};



  const [currentPage, setCurrentPage] = useState(1); 
  const totalSteps = 6; // Total number of steps

  // Function to handle navigation to the next page
  const goToNextPage = () => {
    if (currentPage < totalSteps) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Function to handle navigation to the previous page
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Function to update the progress bar
  function updateProgressBar() {
    const percentComplete = ((currentPage - 1) / (totalSteps - 1)) * 100;
    $(".progress-bar")
      .css("width", percentComplete + "%")
      .css("font-size", "0")
      .css("background-color", "#4CAF50")
      .attr("aria-valuenow", percentComplete)
      .text(percentComplete.toFixed(0) + "%");
  }

  // useEffect hook to update progress bar whenever currentPage changes
  useEffect(() => {
    updateProgressBar();
  }, [currentPage]);

  const [isChecked, setIsChecked] = useState(false); // State variable for checkbox
  const [isValid, setIsValid] = useState(false); // State variable for validation status

  // Handler for checkbox change
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle checkbox state
    setIsValid(false); // Reset validation status when checkbox changes
  };

  // Handler for next button click
  const handleNextButtonClick = () => {
    if (!isValid) {
      toast.error('Please select the checkbox to proceed.'); 
    }
    else {
      goToNextPage();
    }
  };

  // Function to simulate going to the next page

  // Validation function
  const validate = () => {
    // Add your validation logic here
    // For example, checking if the checkbox is checked
    setIsValid(isChecked);
  };

  // Call the validation function whenever the checkbox state changes
  // This will update the validation status accordingly
  useEffect(() => {
    validate();
  }, [isChecked]);

  const validateForm1 = () => {
    let formIsValid = true;
    let newErrors = {};

    if (!Sfname.trim()) {
      formIsValid = false;
      newErrors["Sfname"] = "First name is necessary";
    }
    if (!Smname.trim()) {
      formIsValid = false;
      newErrors["Smname"] = "Middle name is necessary.";
    }
    if (!Slname.trim()) {
      formIsValid = false;
      newErrors["Slname"] = "Last Name is necessary.";
    }

    // if (!Ffname.trim()) {
    //   formIsValid = false;
    //   newErrors["Ffname"] = "Fisrt name is necessary.";
    // }

    // Validation for father's middle name
    if (!Fmname.trim()) {
      formIsValid = false;
      newErrors["Fmname"] = "Middle name is necessary.";
    }

    // Validation for father's last name
    // if (!Flname.trim()) {
    //   formIsValid = false;
    //   newErrors["Flname"] = "Last name is necessary.";
    // }

    //Validation for selected state
    if (!selectedState) {
      formIsValid = false;
      newErrors["selectedState"] = "State Select";
    }

    // Validation for selected district
    if (!selectedDistrict) {
      formIsValid = false;
      newErrors["selectedDistrict"] = "District Select";
    }

    // Validation for selected talukaS
    if (!selectedTaluka) {
      formIsValid = false;
      newErrors["selectedTaluka"] = "Taluka Select";
    }

    // Validation for village
    if (!Village.trim()) {
      formIsValid = false;
      newErrors["Village"] = "Village name is necessary.";
    }

    // Validation for house
    if (!House.trim()) {
      formIsValid = false;
      newErrors["House"] = "House number/colony is necessary.";
    }

    // Validation for pin
    if (!Pin.trim()) {
      formIsValid = false;
      newErrors["Pin"] = "Pincode is necessary.";
    }

    // Validation for mobile number
    if (!Smobile.trim()) {
      formIsValid = false;
      newErrors["Smobile"] = "Contact number is necessary.";
    }

    // Validation for parent's mobile number
    if (!Pmobile.trim()) {
      formIsValid = false;
      newErrors["Pmobile"] = "Contact number is necessary.";
    }

    // Validation for date of birth
    if (!date) {
      formIsValid = false;
      newErrors["date"] = "BirthDate is necessary.";
    }

    // Validation for age

    // Validation for father's occupation
    if (!FOccupation.trim()) {
      formIsValid = false;
      newErrors["FOccupation"] = "Parents Occupation is necessary.";
    }
    if (!email.trim()) {
      formIsValid = false;
      newErrors["email"] = "Email Id is necessary.";
    }
    // // Validation for subcategory
    // if (!SubCategory.trim()) {
    //   formIsValid = false;
    //   newErrors["SubCategory"] = "Student Sub-Category is necessary";
    // }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleNextClick = () => {
    if (validateForm1()) {
      goToNextPage(); // Navigate to the next page only if form validation passes
    } else {
      // Form validation failed, display error messages or handle accordingly
    }
  };


  const calculateAge = (birthdate) => {
    if (!birthdate) return;

    const today = new Date();
    let age = today.getFullYear() - birthdate.year();
    const m = today.getMonth() - birthdate.month();
    if (m < 0 || (m === 0 && today.getDate() < birthdate.date())) {
        age -= 1;
    }
    return age;
};

const handleDateChange = (selectedDate) => {
    if (!selectedDate) {
        setDate(null);
        setAge('');
        return;
    }

    const newAge = calculateAge(selectedDate);
    if (newAge < 14) {
        setErrors({ ...errors, date: "Age must be at least 14 years." });
        setDate(null);
        setAge('');
    } else {
        setErrors({ ...errors, date: null });
        setDate(selectedDate);
        setAge(newAge);
    }
};

useEffect(() => {
    if (date) {
        setAge(calculateAge(date));
    }
}, [date]);


  const handleAdharCardChange = (e) => {
    const file = e.target.files[0];
    // Additional validation can be added here
    setAdharCard(file);
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    // Additional validation can be added here
    setPhoto(file);
  };

  const handleMarkListChange = (e) => {
    const file = e.target.files[0];
    // Additional validation can be added here
    setMarkList(file);
  };

  const handleProficiencyDocChange = (e) => {
    const file = e.target.files[0];
    // Additional validation can be added here
    setProficiencyDoc(file);
  };

  const validateFormup = () => {
    return adharCard && photo && markList;
  };

  // Function to handle upload event
  const handleup = () => {
    const isValid = validateFormup();
    if (isValid) {
      // Proceed to the next page
      goToNextPage();
    } else {
      // console.log("Form validation failed.");
    }
  };
  function toggleSubmitButton() {
    const checkbox = document.getElementById("agreeCheckbox");
    const submitButton = document.querySelector(".nextBtn");

    // Enable/disable the submit button based on checkbox state
    submitButton.disabled = !checkbox.checked;
  }
  const input = {
    border: "1px solid",
    height:"35px",
  color:"black"  }

   

  return (
    <>
     <ToastContainer />
    <SideNavbarStudentReg/>
     <section className="home-section">
       <NavBarStudentReg/> 
 
    
        <div >
          
          <form>
            {currentPage === 1 && (
              <div className="my-3 px-md-4 px-3 mx-md-4 mx-2 py-4"  style={{ textAlign: 'left', borderRadius: '15px', backgroundColor: '#dafddc',}}  id="step-1">
              <h3 style={{color: '#45a049'}}>Rules</h3>
                <div>
                  <div style={{lineHeight:"40px"}}>
                  {/* <div>
                 
                      (१) दिगंबर जैन पालन करण्याची प्रतिज्ञा घेऊनच प्रवेश दिला
                      जाईल. प्रवेश घेतेवेळी बोर्डिंग कमिटी वेळोवेळी ठरवेल त्या
                      टर्मस् व डिपॉझिटची रक्कम विद्याथ्यनि भरली पाहिजे,
                      <br /> (२) बोर्डिंगमध्ये प्रवेश मिळविण्यासाठी बोर्डिंगच्या
                      छापील फॉर्मवर विद्याथ्यनि फॉर्म भरतेवेळी विचारलेली माहिती
                      पूर्णपणे खरी दिली पाहिजे. पूर्णपणे न भरलेल्या अगर चुकीची
                      माहिती भरलेल्या फॉर्मचा विचार केला जाणार नाही व तो रद्द
                      करण्यात येईल.
                      <br />
                      (३) एखाद्या विद्यार्थ्याने चुकीची माहिती देऊन
                      बोर्डिंगमध्ये प्रवेश मिळवलेले आढळून आल्यास त्यास
                      बोर्डिंगमधून ताबडतोब काढून टाकण्यात येईल.
                      <br />
                      (4) बोर्डिंगचे वर्षे १ जून ते ३० एप्रिल पर्यंत समजणेत
                      येईल. यामध्ये पहिली टर्म १ जून ते ३० ऑक्टोबर व दुसरी टर्म
                      १ नोव्हेंबर ते ३० एप्रिल अशी समजणेत येईल. ज्या
                      विद्यार्थ्यांची टर्म शैक्षणिक वर्षापासून सुरु होईल त्याची
                      परिक्षा संपेपर्यंत टर्म समजणेची आहे. त्यापुढे समजणेचे
                      नाही. पण सदरची टर्म जास्तीत जास्त ३० जून अखेर पर्यंतची
                      समजणेची आहे. जादा कालावधीसाठी वेगळी फी भरावी लागेल.
                      <br />
                      (५) प्रवेश अर्जासोबत विद्यार्थ्याने आपल्या शाळेचे अगर
                      महाविद्यालयाच शैक्षणिक संस्थेचे बोनाफाईड सर्टिफिकेट व
                      गुणपत्रक हजर केले पाहिजे.
                      <br /> (६) बोर्डिंगच्या प्रत्येक विद्यार्थ्याकडे आपले
                      ओळखपत्र स्वतः जवळ असले पाहिजे.
                      <br />
                      (७) विद्यार्थ्यास बोर्डिंगमध्ये प्रवेश देणे अगर नाकारणे हे
                      सुपरिटेंडेंट, सेक्रेटरी अगर त्यांच्या कमिटीच्या अधिकारात
                      राहील.
                      <br />
                      (८) जैन धर्मरुढी, परंपरा, सवयी, पंथ इत्यादी अनुसार पुर्णतः
                      शाकाहारी व निरव्यसनी विद्यार्थ्यांनाच प्रवेश दिला जाईल.
                      <br />
                      (९) बोर्डिंगमध्ये होणाऱ्या धार्मिक कार्यक्रमास,
                      गुरुवारच्या आरतीस आणि धर्मसंस्कार शिबीरास गैरहजर राहणा-या
                      विद्यार्थ्यास वसतिगृहातून काढून टाकणेस पात्र होईल, अशा
                      विद्यार्थ्यास पुन्हा प्रवेश मिळणार नाही प्रत्येक
                      विद्यार्थ्यांचे सर्व धार्मिक शिक्षणास व कार्यक्रमास
                      नियमितपणे हजर राहिले पाहिजे व धार्मिक परीक्षा दिली पाहिजे.
                      <br />
                      (१०) बोर्डिंगमध्ये हौशाका केशवराव चौगुले अन्नपुर्णा
                      भोजनगृह चालवले जाते. प्रत्येक विद्याध्यनि बोर्डिंगच्या
                      भोजनालयात जेवण घेतले पाहिजे. जर एखाद्या। या बाबत काही
                      जास्त अडचण असेल त्याने मॅनेजिंग कमिटीची पूर्व परवानगी
                      घेऊनच त्यास काही अटींवर प्रवेश दिला जाईल. विद्याथ्यांन
                      <br /> (११) विद्यार्थ्यास त्याच्या गैरवर्तनाबद्दल कोणतेही
                      कारण न देता वसतिगृहातून काढून टाकणेचा अथवा अन्य शिक्षा
                      करणेचा अधिकार सुपरिटेंडेंट व सेक्रेटरी यांना राहील.
                      <br />
                      (१२) बोर्डिंगच्या नियमामध्ये आवश्यक ते सर्व बदल करणेचा
                      अथवा दुरुस्ती करणेचा अधिकार बोर्डिंग कमिटीस आहे.
                      <br />
                      (१३) विद्यार्थ्यांस बाहेर गावी जायचे असेल तर त्याने
                      सुपरिटेंडेंट यांची पूर्व परवानगी घेऊनच जाणे is necessary.
                      विद्याथ्यनि त्याच्या ताब्यात असणाऱ्या साहित्याची योग्य
                      काळजी घेतली पाहिजे. (उदा. काचा, इलेक्ट्रिक फिटींग,
                      तावदाने, फर्निचर, बोर्डिंगच्या साहित्याचे नुकसान करणे)
                      भिंतीत खिळे ठोकणे इ. गोष्टी करु नयेत तसे केलेचे अढळून आलेस
                      त्याची नुकसान भरपाई विद्यार्थ्याकडून भरुन घेतली जाईल.
                      <br />
                      (१४) बोर्डिंगमध्ये कोणत्याही विद्यार्थ्यांच्या आप्तास अथवा
                      पाहुण्यास रात्री १० चे नंतर राहाता येणार नाही. त्याचेसाठी
                      निराळी गेस्टरुमची व्यवस्था केली जाईल. तथापि या
                      गेस्टरुमध्ये राहणेसाठी सुपरिटेंडेंट यांची पूर्व परवानगी
                      घेणे is necessary.
                      <br />
                      (१५) बोर्डिंगमध्ये ग्रंथालयाची सोय आहे. विद्यार्थास
                      त्याच्या बोर्डिंगच्या ओळखपत्रावर एका वेळी एकच पुस्तक
                      मिळेल. पुस्तक घेतल्यापासून १० दिवसांच्या आत ग्रंथालयात
                      पुस्तक जमा करायला पाहिजे. अन्यथा प्रत्येक दिवसाला १० रु.
                      दंड आकारला जाईल. बोर्डिंगच्या आवारात फटाके वाजविण्यावर
                      सक्त मनाई आहे.
                      <br />
                      (१६) वसतिगृहातील विद्यार्थी आजारी असल्यास अगर एखादा
                      विद्यार्थी बराच काळ खोलीत राहत नसल्यास त्याची माहिती
                      सुपरिटेंडेंट यांना त्या खोलीतील अन्य विद्यार्थ्यांनी लेखी
                      कळविणे is necessary.
                      <br />
                      (१७) विद्यार्थ्यांची खोली कोणत्याही वेळी तपासण्याचा अधिकार
                      बोर्डिंगचे सुपरिटेंडेंट व सेक्रेटरी यांना आहे.
                      <br />
                      (१८) वसतिगृहात व आवारात मद्यपान व धुम्रपान करणेस
                      विद्यार्थ्यांना सक्त मनाई आहे.
                      <br />
                      (१९) रात्री १० नंतर बोर्डिंगचे मेनगेट बंद कले जाईल.
                      त्यानंतर विद्यार्थ्यांना बोर्डिंगबाहेर जाता येणार नाही व
                      आतही प्रवेश मिळणार नाही.
                      <br />
                      (२०) प्रत्येक विद्याथ्यनि आपण बोर्डिंगमध्ये शिक्षण
                      घेण्यासाठी व अभ्यास करण्यासाठी आलो आहोत याची जाणीव नेहमी
                      ठेवली पाहिजे.
                      <br />
                      (२१) विद्याभ्यनि मोकळ्या वेळेत बोर्डिंगमधील इतर खोल्यामधून
                      अगर बोर्डिंगच्या आवारात गप्पा मारत इतरत्र न फिरता त्याचा
                      अभ्यासासाठी उपयोग करून घ्यावा.
                      <br />
                      (२२) प्रायेक दिवशी बोर्डिंग मार्फत सकाळी ६ वाजता प्रार्थना
                      होईल त्यास सर्व विद्याथ्यांनी हजर राहिले पाहिजे.
                      <br />
                      (२३) विद्याथ्याने कोणत्याही राजकिय पक्षापासून अलीमपणे
                      बोर्डिंगमध्ये राहिले पाहिजे.
                      <br />
                      (२४) विद्याव्यनि पाण्याचा वापर योग्य तितकाच करावा, अनाठाई
                      पाणी वापरून बाया घालवू नये. पाण्याचा नळ व चावी यांची
                      मोडतोड करु नये,
                      <br />
                      (२५) प्रत्येक विद्याथ्यनि बोर्डिंगमध्ये संडासचा योग्य वापर
                      केला पाहिजे, बापर करण्यापूर्वी व वापर केल्यानंतर न चुकता
                      पाणी टाकावे म्हणजे संडास साफ राहणेस मदत होईल. चाथरूमध्ये
                      लघवी करु नये, त्यास मनाई आहे.
                      <br />
                      (२६) आपान्या खोलीतील केरकचरा कोंडाळ्यातच टाकावा च आपली
                      खोली व बोर्डिंग स्वच्छ ठेवावे.
                      <br />
                      (२७) बोर्डिंगच्या आवारात रंगपंचमी खेळणेस तसेच एखाद्या
                      विद्यार्थ्याला त्याच्या इच्छेविरुध्द जबरदस्तीने रंग लावणेस
                      सक्त मनाई आहे.
                      <br />
                      (२८) बोर्डिंगच्या नोटीस बोर्डावर सुपरिटेंडेंट बांच्या
                      पूर्वपरवानगीशिवाय विद्यार्थ्यांना कोणतीही सूचना अगर माहिती
                      लिहिणेस सक्त मनाई आहे.
                      <br />
                      (२९) बोर्डिंगतर्फे विद्यार्थ्याचे गॅदरिंग कोणत्याही
                      परिस्थितीत घेतले जाणार नाही. याची विद्यार्थानी नोंद
                      घ्यावी. त्याबाबत तक्रारही चालणार नाही.
                      <br />
                      (३०) विद्यार्थ्याने आपल्या खोलीत कोणत्याही प्रकारचे
                      पोस्टर्स, चित्रे चिकटवणेचे नाहीत. तसेच खोलीच्या भिंतीना
                      मोठे खिळे ठोकणेचे नाहीत.
                      <br />
                      (३१) बोर्डिंगच्या मालकीच्या कोणत्याही वस्तूची नासधूस अगर
                      नुकसानी करु नये, केलेस किंमत संबंधित विद्यार्थ्यांकडून जमा
                      करुन घेतली जाईल.
                      <br />
                      (३२) बाथरुममध्ये कागद, कापडी बोळे व दगड विटा वगैरे काहीही
                      टाकू नये. तसेच जेवणाचे डबे बाथरुममध्ये धुवू नयेत.
                      <br />
                      (३३) प्रत्येक विद्याथ्यनि जेवणाच्या डब्यातील शिल्लक
                      राहिलेले अन्न बोडींगने पुरविलेल्या डब्यातच टाकावे.
                      <br />
                      (३४) आरोग्याच्या दुहीने विद्याथ्यनि बोर्डिंगच्या आवारात व
                      इतरत्र कोठेही तंबाखू खाजून अगर पान खावून धुंकू नये, (१५)
                      विद्याथ्यांनी बोर्डिंगच्या मेन गेट वरील नियमांचे पालन केले
                      पाहिजे.
                      <br />
                      (३६) बोर्डिंगच्या पदाधिका-याविरुध्द विद्यार्थ्यांस संप,
                      चेराव घालेजेस अगर उपोषण करण्यास सक्त मनाई असून मने
                      भडकवण्याच्या विरुध्द कडक कारवाई केली जाईल प्रसंगी
                      वसतिगृहातून काढून टाकले जाईल.
                      <br />
                      (३७) विद्याध्यनि आपले बाहन (सायकल, मोटर सायकल वगैरे)
                      बोर्डिंगमध्ये सोय केलेल्या ठिकाणी व्यवस्थित ठेवले पाहिजे.
                      शिस्तीच्या दृष्टीने इतरत्र वाहन ठेवू नये.
                      <br />
                      (३८) बोर्डिंगच्या आवारात विद्याथ्यनि क्रिकेटचा खेळ खेळू
                      नये, तसेच पते, जुगार बर्गर बोर्डिंगमध्ये खेळण्यास सक्त
                      मनाई आहे.
                      <br />
                      (३९) बोर्डिंगमध्ये विद्याथ्यांच्या सोईसाठी आप्पासाहेब
                      गणपती कागवाडे दवाखाना चालविला जातो. विद्यार्थ्यांनी याचा
                      लाभ घ्यावा.
                      <br />
                      (४०) वसतीगृहात राहणा-या विद्यार्थ्यांच्या वडिलांनी अगर
                      पालकांनी विद्यार्थ्यांच्या शैक्षणिक काळात त्याच्या
                      अभ्यासाबद्दल व वर्तणुकीबद्दल बोर्डिंगचे सुपरिटेंडेंट यांना
                      किमान महिन्यातून एक वेळ भेटून चौकशी करणे व सूचना देणे is
                      necessary. तसे बडिलांनी अगर पालकांनी न केल्यास त्याची
                      जबाबदार संस्थेवर राहणार नाही. (४१) गैरवर्तणूक, गैरभाषा,
                      गैरहालचाली अथवा बोर्डिंग नियमभंग करणाऱ्या विद्याथ्यांची
                      भरलेली टर्म फी त्यास परत दिली जाणार नाही.
                      <br />
                      (४२) विद्यार्थ्यांच्या कोणत्याही वस्तूच्या सुरक्षिततेची
                      जबाबदारी संस्थेवर नाही. विद्याथ्यांनी आपल्या वस्तू
                      काळजीपूर्वक आपणच सांभळणेच्या आहेत. व विषयाच्या सर्व
                      परिक्षांना बसले पाहिजे व त्याने
                      <br />
                      (४३) प्रत्येक विद्याथ्यनि ज्या त्या वर्गाच्या गुणपत्रक
                      बोर्डिंगच्या कार्यालयात सादर कले पाहिजे. तसे न केलेस पुढील
                      वेळेस प्रवेश देताना विचार केला जाईल.
                      <br />
                      (४४) बोर्डिंगचे लिखित नियम, उपनियम यामध्ये वेळोवेळी होणारे
                      बदल याची विद्याच्यानि नोंद घेऊन त्याप्रमाणे वागले पाहिजे.
                      <br />
                      (४५) ज्यांना सुट्टीत रहावयाचे असेल त्यांनी खालील गोष्टींची
                      पूर्तता करणे जरुरीचे आहे. (अ) टर्म संपण्यापूर्वी ह्याबाबत
                      परवानगीसाठी अर्ज करणे व परवानगी घेणे, (ब) त्यासाठी
                      मॅनेजिंग कमिटीने ठरविलेली रक्कम आगाऊ भरणे (क) टर्म
                      संपलेनंतर विद्याथ्यांने वसतिगृह सोडले पाहिजे. (ड) सुट्टीत
                      राहणे हा विद्यार्थ्यांचा हक नसून त्यासाठी परवानगी देणे
                      अथवा न देणे से चेअरमन, सेक्रेटरी, सुपरिटेंडेंट यांचे
                      अधिकारात राहील. (इ) उन्हाळ्याचे सुट्टीत १ मे पासून
                      विद्याथ्याँनी वसतिगृहात राहू नये. अभ्यासाची अगर परिक्षेची
                      अडचण असेल तर तीची आवश्यकता व खात्री पटवून दिलेस त्या
                      विद्यार्थ्यांस बोर्डिंगमध्ये राहणेबद्दल जरुर ती परवानगी
                      दिली जाईल व त्याचे योग्य भाडे आकारले जाईल.
                      <br />
                      (४६) प्रत्येक खोलीमध्ये बोर्डिंगने दिलेल्या बल्ब (ट्युब
                      वापरणेच्या आहेत, ज्यादा कनेक्शन व बल्ब वापरणेचे नाहीत.)
                      <br />
                      (४७) प्रत्येक विद्याथ्यनि दक्षिण भारत जैन सभेचे कमीत कमी ५
                      मेंबर्स मिळवून द्यावेत.
                      <br />
                      (४८) विद्यार्थ्यास बोर्डिंगमध्ये प्रवेश नाकारणेत आला तर
                      त्याने भरलेल्या रकमेपैकी प्रवेश रक्कम वजा करून त्यास
                      बाकीची रक्कम आदा केली जाईल.
                      <br />
                      (४९) बोर्डिंगमध्ये प्रवेश घेतल्यानंतर विद्यार्थ्यांस काही
                      कारणामुळे प्रवेश नको असेल आणि प्रवेश घेतलेपासून १५ दिवसाचे
                      आत त्याने तसे लेखी कळविलेस त्या विद्यार्थ्यांस प्रवेश फी व
                      द. भा. जैन सभेची मेंबर वर्गणीची रक्कम वजा करुन बाकीची
                      रक्कम आदा केली जाईल.
                      <br />
                      (५०) बोर्डिंगमध्ये प्रामुख्याने जे विद्यार्थी दोन्हीही
                      टर्म करीता राहणार असतील त्या विद्यार्थ्यांना प्रथम दिला
                      जाईल. त्यानंतर जागा शिल्लक असतील तरच एका टर्मच्या
                      विद्याथ्यर्थ्यांना प्रवेश दिला जाईल.
                      <br />
                      (५१) ज्या विद्यार्थ्यांस बोर्डिंगमध्ये प्रवेश दिला असेल
                      त्या विद्यार्थ्यांना ज्या खोल्या दिल्या असतील त्याच
                      खोल्यामध्ये विद्यार्थ्यांने राहण्याचे आहे. अन्यथा
                      सुपरिटेंडेंट यांच्या परवानगीशिवाय परस्पर जे विद्यार्थी
                      खोल्या बदलत असतील त्या विद्यार्थ्यांचा प्रवेश रह केला
                      जाईल.
                      <br />
                      (५२) प्रवेश अर्जाप्रमाणे ज्या विद्याथ्यर्थ्यांला
                      बोर्डिंगमध्ये प्रवेश दिला असेल त्याच विद्यार्थ्यांला
                      बोर्डिंगमध्ये राहता येईल. त्याच्या नांवावर इतरांना राहता
                      येणार नाही. जर असा एखादा विद्यार्थी रहात असेल तर त्या
                      खोलीतील विद्यार्थ्यांवर योग्य ती कडक कारवाई केली जाईल.
                      <br />
                      (५३) वसतिगृहातील विद्यार्थ्याने स्वतःची खोली स्वच्छ व
                      टापटीप ठेवावी. त्याचप्रमाणे खोलीतील लाईट कनेमान्स,
                      अनावश्यक चालू ठेवू नये, झोपतेवेळी व बाहेर जातेवेळ लाईट बंद
                      करणे is necessary. विद्याव्योंने पंखा, इखी, रेडिओ, हिटर
                      अगर तत्सम विज्ञ उपकरणे वापर नयेत, तसे आढळून आल्यास ते
                      साहित्य जम करून कारवाई केली जाईल,
                      <br />
                      (५४) वसतिगृहातील राहणान्या विद्याथ्यनि जर एखादी स्वतःची
                      वस्तू हरविली असल्यास अगर चोरीस गेली असल्यास त्याबात सत्वर
                      बोर्डिंगच्या कार्यालयाकडे लेखी तक्रार अर्ज देणेचा आहे जर
                      एखाद्या विद्याथ्यनि बराच काळ तक्रार केली नसल्यास व पुढे
                      हरवलेल्या अगर चोरीस गेलेल्या वस्तूबाबत वाद उपस्थित झालेस व
                      निर्णय देता येणे अशक्य झालेस ती वस्तू बोर्डिंगच्या मालकीची
                      समजली जाईल.
                      <br />
                      (५५) बोर्डिंगच्या संस्थेमार्फत वसतिगृहात रहात असलेल्या
                      गरीब, होतकर व हुशार विद्याथ्यनि जर मेडिकल अगर
                      इंजिनिअरिंगला प्रवेश मिळविला असेल तर त्या विद्याथ्यांना
                      कर्जाऊ शिष्यवृत्ती देण्याची व्यवस्था करण्यात आलेली आहे.
                      <br />
                      (५६) विद्याथ्यांनी आपली परिक्षा संपल्यानंतर ४ दिवसांच्या
                      आत आपले खोलीतील सर्व साहित्य काढून घेऊन खोली सोडल्याचा
                      फॉर्म भरुन खोली रितसर बोर्डिंगच्या ताव्यात द्यावी. या
                      नियमांचे पालन करणान्या विद्याथ्यांलाच डिपॉझिट रक्कम मिळेल.
                      अन्यथा सदर डिपॉझीटची रक्कम बोर्डिंगच्या अनामत खातेस जमा
                      केली जाईल.
                      <br />
                      (५७) ज्या विद्यार्थ्यांनी बोर्डिंगमध्ये दोन्हीही टर्मकरिता
                      जर प्रवेश घेतला असेल त्या विद्यार्थ्यांला १५ जुलै नंतर अगर
                      दुसऱ्या टर्ममध्ये जर रहाक्याचे नसल्यास त्या विद्याथ्याँस
                      मध्यंतरी अगर दुस-या टर्ममध्ये बोर्डिंग सोडल्याबद्दल टर्म
                      फी ची रक्कम परत दिली जाणार नाही.
                      <br />
                      (५८) बोर्डिंगमध्ये विद्यार्थ्यांला प्रवेश दिल्यानंतर त्या
                      खोलीतील प्रत्येक विद्याथ्यांनी शाळा अगर कॉलेजमधील अल्प
                      सुट्टीत अगर दिर्घ सुट्टीत परस्पर बोडींग व्यतिरिक्त इतरांना
                      खोलीत जर राहण्यास दिल्याचे आढळल्यास त्या खोलीतील सर्व
                      विद्यार्थ्यांवर नियमबाह्य वर्तन केले म्हणून योग्य ते इलाज
                      केले जातील.
                      <br />
                      (५९) विद्याथ्याने आपले वर्तन इतर विद्याथ्यांशी,
                      सुपरिटेंडेंट, सेक्रेटरी, चेअरमन व मॅनेजिग कमिटीतील
                      पदाधिकाऱ्यांशी सौजन्याने, शिस्तपूर्ण आणि सहकार्याचे
                      ठेवावे.
                      <br />
                      (६०) वसतिगृहातील विद्यार्थ्यांना त्याचप्रमाणे शेजारील
                      खोलीतील विद्यार्थ्यांना आपल्या वर्तनाचा त्रास होऊ नये याची
                      खबरदारी घेतली पाहिजे.
                      <br />
                      (६१) ज्या विद्यार्थ्यांच्या परिक्षा झाल्या आहेत. त्यांनी
                      सत्वर खोलीचा ताबा वसतिगृह व्यवस्थापनाकडे देण्याचा आहे. तसा
                      जर दिला गेला नाही तर व्यवस्थापनाकडून सदर खोलीचे कुलुप
                      तोडून ताबा घेतला जाईल. त्याबाचत कोणतीही तक्रार ऐकली जाणार
                      नाही.
                      <br />
                      (६२) विद्याथ्यांनी मौल्यवान वस्तू बोर्डिंगवर वापरु नयेत,
                      अशा वस्तू हरविलेस अगर चोरीस गेल्यास बोर्डिंग जबाबदार
                      राहणार नाही.
                      <br />
                      (६३) विद्यार्थ्यांनी जिमचा वापर योग पद्धतीने करावा,
                      गैरवर्तणूक अढळेस सदर विद्यार्थ्यांची जिमची सुविधा बंद
                      करण्यात येईल.
                      <br />
                      (६४) मोबाईल वर गेम खेळताना, निरर्थक व्हिडीओ बघताना तसेच
                      टाईमपास करत असल्यास अशा विद्याथ्यांवर कडक कारवाई करण्यात
                      येईल.
                      <br />
                      (६५) गरम पाण्याची सोय पहाटे ५.०० वाजलेपासून सकाळी ८.००
                      वाजेपर्यंतच उपलब्ध राहील. वरील प्रमुख नियम व वेळोवेळी
                      करण्यात येणारे नियम विद्यार्थ्यांनी अत्यंत काटेकोरपणे
                      पाहिले पाहिजेत. नियमबाह्य वर्तन करणाऱ्या
                      विद्याथ्र्यांविरुध्द इलाज केले जातील. प्रसंगी बोर्डिंगमधून
                      काढले जाईल.
                      <br />
                      </div> */}

<div>
<ul>
        {rules.map(rule => (
          <li key={rule.id}>
            {rule.serial_no}. &nbsp; {rule.description}<br /> 
          </li>
        ))}
      </ul>
</div>


                      {/* Next button */}
                      <Row>
                      <Col sm={1} md={1} >
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                      </Col>
                      <Col sm={11} md={11} ></Col>
                      </Row>
                      <label style={{ color: "black", marginLeft: "10px" }}>
                        {" "}
                        वरील सर्व नियम व अटी मान्य आहेत
                      </label>
                      <div
                        className="button-container  text-center"
                      >
                       <button
                    type="button"
                    className={`text-center fs-4 btn3 btn-primary nextBtn ${
                      !isValid && 'disabled'
                    }`}
                    style={{
                      backgroundColor: 'rgb(23, 160, 160)',
                      color: 'black',
                      width: '100px',
                      border: '0px',
                      marginTop: '-10px',
                    }}
                    onClick={handleNextButtonClick}
                  >
                    Next
                  </button>
                      </div>
               
                    <br />
                  </div>
                </div>
              </div>
            )}

            {currentPage === 2 && (
              <div id="step-2">
                <div className="mx-2 mx-md-4 px-md-4 px-2 my-4 shadow py-4" style={{backgroundColor:"rgba(247, 246, 246, 0.781)",borderRadius:"10px"}}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                <h2 className="pt-3" style={{color: 'maroon'}}>Personal Information</h2>
                </div>
           
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={afterlogo} />
                </div>

                    <div className="row" >
                      <label className="mt-3">Student's Full Name</label>
                      <div className="mt-md-2 mt-3 col-md-4">
                        <input
                          maxLength={100}
                          type="text"
                          required
                          size="sm"
                          style={input}
                          id="name"
                          value={Sfname}
                          name="s_fname"
                          onChange={(e) => setSfname(e.target.value)}
                          placeholder="First Name"
                          className={`form-control ${
                            errors.Sfname ? "error-input" : ""
                          }`}
                        />
                        {errors.Sfname && (
                          <div className="error-message">{errors.Sfname}</div>
                        )}
                      </div>

                      {/* Additional input fields */}

                      <div className="mt-md-2 mt-3 col-md-4">
                        <input
                          maxLength={100}
                          type="text"
                          style={input}
                          required="required"
                          className={`form-control ${
                            errors.Smname ? "error-input" : ""
                          }`}
                          id="name"
                          value={Smname}
                          name="s_mname"
                          onChange={(e) => setSmname(e.target.value)}
                          placeholder="Middle Name"
                        />
                        {errors.Smname && (
                          <div className="error-message">{errors.Smname}</div>
                        )}
                      </div>
                      <div className="mt-md-2 mt-3 col-md-4">
                        <input
                          maxLength={100}
                          type="text"
                          style={input}
                          required="required"
                          className={`form-control ${
                            errors.Slname ? "error-input" : ""
                          }`}
                          id="name"
                          value={Slname}
                          name="s_lname"
                          onChange={(e) => setSlname(e.target.value)}
                          placeholder="Last Name"
                        />
                        {errors.Slname && (
                          <div className="error-message">{errors.Slname}</div>
                        )}
                      </div>
                    </div>
                      <Row >
                      <label className="mt-3"> Father's Full Name</label>
                      <Col sm={12} md={4} className="mt-md-2 mt-3">
                        <input
                          maxLength={100}
                          type="text"
                          style={input}
                          required="required"
                          className={`form-control form-control-sm ${
                            errors.Smname ? "error-input" : ""
                          }`}
                          id="name"
                          value={Smname}
                          name="father_fname"
                          // onChange={(e) => setFfname(e.target.value)}
                          placeholder="First Name"
                        />{" "}
                        {errors.Smname && (
                          <div className="error-message">{errors.Smname}</div>
                        )}
                      </Col>

                      <Col sm={12} md={4} className="mt-md-2 mt-3">
                        <FormControl
                          maxLength={100}
                          type="text"
                          style={input}
                          required="required"
                          className={`form-control ${
                            errors.Fmname ? "error-input" : ""
                          }`}
                          id="name"
                          value={Fmname}
                          name="father_mname"
                          onChange={(e) => setFmname(e.target.value)}
                          placeholder="Middle Name"
                        />{" "}
                        {errors.Fmname && (
                          <div className="error-message">{errors.Fmname}</div>
                        )}
                      </Col>
                      <Col sm={12} md={4} className="mt-md-2 mt-3">
                        <input
                          maxLength={100}
                          type="text"
                          style={input}
                          className={`form-control ${
                            errors.Slname ? "error-input" : ""
                          }`}
                          id="name"
                          value={Slname}
                          name="father_lname"
                          // onChange={(e) => setSlname(e.target.value)}
                          placeholder="Last Name"
                        />
                        {errors.Slname && (
                          <div className="error-message">{errors.Slname}</div>
                        )}
                      </Col>
                    </Row>

                    <Row>
                        <label className="mt-3">
                          Address
                        </label>
                        <Col sm={12} md={4} className="mt-md-2 mt-3">
                        <select
                            required
                            style={input}
                            className={`form-control ${
                            errors.selectedState ? "error-input" : ""
                            }`}
                            id="state"
                            value={selectedState}
                            name="state"
                            onChange={handleStateChange}
                        >
                            <option value="">State</option>
                            {stateList.map((state) => (
                            <option key={state.id} value={state.id}>
                                {state.state_name}
                            </option>
                            ))}
                        </select>
                        {errors.selectedState && (
                            <div className="error-message">
                            {errors.selectedState}
                            </div>
                        )}
                        </Col>

                      <Col sm={12} md={4} className="mt-md-2 mt-3">
                        <select
                          required
                          style={input}
                          className={`form-control ${
                            errors.selectedDistrict ? "error-input" : ""
                          }`}
                          id="district"
                          value={selectedDistrict}
                          name="district"
                          onChange={handleDistrictChange}
                        >
                          {errors.selectedDistrict && (
                            <div className="error-message">
                              {errors.selectedDistrict}
                            </div>
                          )}

                          <option value="">District</option>
                          {districtList.map((district) => (
                            <option key={district.id} value={district.id}>
                              {district.districtenname}
                            </option>
                          ))}
                        </select>
                      </Col>
                      <Col sm={12} md={4} className="mt-md-2 mt-3">
                        <select
                          required
                          style={input}
                          className={`form-control ${
                            errors.selectedTaluka ? "error-input" : ""
                          }`}
                          id="taluka"
                          value={selectedTaluka}
                          name="taluka"
                          onChange={handleTalukaChange}
                        >
                          <option value="">Taluka</option>
                          {talukaList.map((taluka) => (
                            <option key={taluka.id} value={taluka.id}>
                              {taluka.talukaenname}
                            </option>
                          ))}
                        </select>{" "}
                        {errors.selectedTaluka && (
                          <div className="error-message">
                            {errors.selectedTaluka}
                          </div>
                        )}
                      </Col>
                      </Row>

                      <Row>
                    <Col sm={12} md={4} className="mt-md-4 mt-3">
                        <input
                          maxLength={100}
                          type="text"
                          style={input}
                          required="required"
                          className={`form-control ${
                            errors.Village ? "error-input" : ""
                          }`}
                          id="name"
                          value={Village}
                          name="village"
                          onChange={(e) => setVillage(e.target.value)}
                          placeholder="Village/City"
                        />{" "}
                        {errors.Village && (
                          <div className="error-message">{errors.Village}</div>
                        )}
                      </Col>

                      <Col sm={12} md={4} className="mt-md-4 mt-3">
                        <input
                          maxLength={100}
                          type="text"
                          style={input}
                          required="required"
                          className={`form-control ${
                            errors.House ? "error-input" : ""
                          }`}
                          id="name"
                          value={House}
                          name="address"
                          onChange={(e) => setHouse(e.target.value)}
                          placeholder="House Name/No/Road/Chowk/Colony/Landmark"
                        />{" "}
                        {errors.House && (
                          <div className="error-message">{errors.House}</div>
                        )}
                      </Col>
                      <Col sm={12} md={4} className="mt-md-4 mt-3">
                        <input
                          maxLength={6} // Limit input to 6 characters
                          type="text"
                          style={input}
                          className={`form-control ${
                            errors.Pin ? "error-input" : ""
                          }`}
                          id="name"
                          value={Pin}
                          name="pincode"
                          onChange={(e) => {
                            const input = e.target.value;
                            // Check if the input consists only of numbers and has a maximum length of 6
                            if (/^\d*$/.test(input) && input.length <= 6) {
                              // Update state only if the input is a number and within the length limit
                              setPin(input);
                            }
                          }}
                          placeholder="Pincode"
                        />
                        {errors.Pin && (
                          <div className="error-message">{errors.Pin}</div>
                        )}
                      </Col>
                    </Row>

                    <Row>
                    <Col sm={12} md={4} className="mt-md-2 mt-2">
                    <label className="mt-md-3 mt-1">
                        contact Number
                      </label>
                      <input
                        id="mobileNumber"
                        style={input}
                        type="text"
                        required="required"
                        className={`form-control ${
                          errors.Smobile ? "error-input" : ""
                        }`}
                        value={Smobile}
                        name="mobile_no"
                        onChange={(e) => setSmobile(e.target.value)}
                        onKeyPress={(e) => {
                          const charCode = e.which ? e.which : e.keyCode;
                          if (
                            charCode > 31 &&
                            (charCode < 48 || charCode > 57)
                          ) {
                            e.preventDefault();
                          }
                        }}
                        maxLength={10}
                        placeholder=" contact Number/Phone"
                      />{" "}
                      {errors.Smobile && (
                        <div className="error-message">{errors.Smobile}</div>
                      )}
                    </Col>
                    <Col sm={12} md={4} className="mt-md-2 mt-2">
                     <label className="mt-md-3 mt-1">
                        parent's Contact No.
                      </label>
                      <input
                        id="mobileNumber"
                        type="text"
                        style={input}
                        required="required"
                        className={`form-control ${
                          errors.Pmobile ? "error-input" : ""
                        }`}
                        value={Pmobile}
                        name="parents_mobno"
                        onChange={(e) => setPmobile(e.target.value)}
                        onKeyPress={(e) => {
                          const charCode = e.which ? e.which : e.keyCode;
                          if (
                            charCode > 31 &&
                            (charCode < 48 || charCode > 57)
                          ) {
                            e.preventDefault();
                          }
                        }}
                        maxLength={10}
                        placeholder=" parents contact Number/Phone"
                      />
                      {errors.Pmobile && (
                        <div className="error-message">{errors.Pmobile}</div>
                      )}
                    </Col>
                    <Col sm={12} md={2} className="mt-md-2 mt-2">
    <label className="mt-md-3 mt-1">Date Of Birth</label>
    <DatePicker
        name="dateofbirth"
        className={`w-100 form-control custom-datepicker ${errors.date ? "error-input" : ""}`}
        required
        value={date}
        style={input}
        onChange={handleDateChange}
        format="DD/MM/YYYY"
        placeholder="BirthDate"
    />
    {errors.date && <div className="error-message">{errors.date}</div>}
</Col>
            <Col sm={12} md={2} className="mt-md-2 mt-2">
                <label className="mt-md-3 mt-1">Age</label>
                <input
                    type="number"
                    required
                    style={input}
                    className={`form-control ${errors.age ? "error-input" : ""}`}
                    name="age"
                    value={age}
                    readOnly
                    placeholder="Age"
                />
                {errors.age && <div className="error-message">{errors.age}</div>}
            </Col>
                   </Row>

                 <Row>
                   <Col sm={12} md={4} className="mt-md-2 mt-2">
                   <label className="mt-md-3 mt-1">
                        Email Id
                      </label>
                      <input
                        maxLength={200}
                        type="email"
                        style={input}
                        required="required"
                        className={`form-control ${
                          errors.FOccupation ? "error-input" : ""
                        }`}
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email Id"
                      />{" "}
                      {errors.email && (
                        <div className="error-message">{errors.email}</div>
                      )}
                    </Col>
                    <Col sm={12} md={4} className="mt-md-2 mt-2">
                    <label className="mt-md-3 mt-1">
                        Student's Sub-Cast
                      </label>
                      <select
                        required
                        style={input}
                        className={`form-control ${error ? "error-input" : ""}`}
                        name="sub_category"
                        value={selectedSubCategory}
                        onChange={(e) => setSelectedSubCategory(e.target.value)}
                      >
                        <option value="">Select an option</option>
                        {subCategories.map((subCategory) => (
                          <option key={subCategory.id} value={subCategory.id}>
                            {subCategory.categoryname}
                          </option>
                        ))}
                      </select>
                      {errors.SubCategory && (
                        <div className="error-message">
                          {errors.SubCategory}
                        </div>
                      )}
                    </Col>
                    <Col sm={12} md={4} className="mt-md-2 mt-2">
                    <label className="mt-md-3 mt-1">
                        Parent's Occupation
                      </label>
                      <input
                        maxLength={200}
                        type="text"
                        style={input}
                        required="required"
                        className={`form-control ${
                          errors.FOccupation ? "error-input" : ""
                        }`}
                        name="father_occupation"
                        value={FOccupation}
                        onChange={(e) => setFOccupation(e.target.value)}
                        placeholder="Parents Occupation"
                      />
                      {errors.FOccupation && (
                        <div className="error-message">
                          {errors.FOccupation}
                        </div>
                      )}
                    </Col>
                    </Row>

                    <Row className="mt-3" style={{display:"flex",justifyContent:"end"}}>
                      <button
                       className="fs-5 btn btn-primary nextBtn"
                        style={{backgroundColor:"rgb(23, 160, 160)",color:"black",width:"120px",border:"0px  "}}
                        type="button"
                        onClick={goToPreviousPage}
                      >
                        Previous
                      </button>
                      <button
                       type="button"
                        className="fs-5 btn btn-primary nextBtn"
                        style={{backgroundColor:"rgb(23, 160, 160)",color:"black",width:"100px",border:"0px  "}}
                        onClick={handleNextClick}
                      >
                        Next
                      </button>
                    </Row>
                
                </div>
              </div>
            )}
            {currentPage === 3 && (
              <div id="step-3">
              <div className="mx-2 mx-md-4 px-md-4 px-2 my-4 py-4 shadow" style={{backgroundColor:"rgba(247, 246, 246, 0.781)",borderRadius:"10px"}}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                <h2 className="pt-3" style={{color: 'maroon'}}>Educational Information</h2>
                </div>
           
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={afterlogo} />
                </div>


                <Row >
                <Col sm={12} md={6} className="mt-md-2 mt-2">
                   <label className="mt-md-3 mt-1">
                      Last Year School/College Name{" "}
                    </label>
                    <input
                      maxLength={200}
                      type="text"
                      style={{color:"black"}}
                      required="required"
                      className={`form-control ${
                        errors.PCollageName ? "error-input" : ""
                      }`}
                      name="l_collagename"
                      value={PCollageName}
                      onChange={(e) => setPCollageName(e.target.value)}
                      placeholder="Last Year School/College Name"
                    />{" "}
                    {errors.PCollageName && (
                      <div className="error-message">{errors.PCollageName}</div>
                    )}
                  </Col>
                  <Col sm={12} md={6} className="mt-md-2 mt-2">
                   <label className="mt-md-3 mt-1">
                      Last Year Standard And Division
                    </label>
                    <input
                      maxLength={200}
                      type="text"
                      style={{color:"black"}}
                      required="required"
                      className={`form-control ${
                        errors.StdDiv ? "error-input" : ""
                      }`}
                      name="std"
                      value={StdDiv}
                      onChange={(e) => setStdDiv(e.target.value)}
                      placeholder=" Last Year Standard And Division
                      "
                    />{" "}
                    {errors.StdDiv && (
                      <div className="error-message">{errors.StdDiv}</div>
                    )}
                  </Col>
                  </Row>

                  <Row>
                  <Col sm={12} md={6} className="mt-md-2 mt-2">
                   <label className="mt-md-3 mt-1">
                      Current School/College Name{" "}
                    </label>
                    <input
                      maxLength={200}
                      style={{color:"black"}}
                      type="text"
                      required="required"
                      className={`form-control ${
                        errors.CCollageName ? "error-input" : ""
                      }`}
                      name="collage_name"
                      value={CCollageName}
                      onChange={(e) => setCCollageName(e.target.value)}
                      placeholder=" Current School/College Name"
                    />{" "}
                    {errors.CCollageName && (
                      <div className="error-message">{errors.CCollageName}</div>
                    )}
                  </Col>

                  <Col sm={12} md={6} className="mt-md-2 mt-2">
                   <label className="mt-md-3 mt-1">
                    Current Standard and Division
                    </label>
                    <input
                      maxLength={200}
                      style={{color:"black"}}
                      type="text"
                      required="required"
                      className={`form-control ${
                        errors.NyearcollageName ? "error-input" : ""
                      }`}
                      name="nextyearcollagename"
                      value={NyearcollageName}
                      onChange={(e) => setNyearcollageName(e.target.value)}
                      placeholder="Current Standard and Division"/>
                    {errors.NyearcollageName && (
                      <div className="error-message">
                        {errors.NyearcollageName}
                      </div>
                    )}
                  </Col>
                 </Row>

                  <Row>
                  <Col sm={12} md={6} className="mt-md-2 mt-2">
                   <label className="mt-md-3 mt-1">
                      Did you stay in sangli jain boarding last year?
                    </label>
                    <select
                      className="form-select"
                      name="Profeciency"
                      style={{color:"black"}}
                      value={Profeciency}
                      onChange={(e) => setProfeciency(e.target.value)}
                      required="required"
                    >
                      <option value>Select</option>
                      <option value="1">Yes</option>
                      <option value="2">No</option>
                    </select>
                  </Col>
                  <Col sm={12} md={6} className="mt-md-2 mt-2">
                   <label className="mt-md-3 mt-1">
                      Have you received a Scholarship from D B Jain Sabha ?
                    </label>
                    <select
                      className="form-select"
                      style={{color:"black"}}
                      name="fellowship"
                      value={Fellowship}
                      onChange={(e) => setFellowship(e.target.value)}
                      required="required"
                    >
                      <option value>Select</option>
                      <option value="1">Yes</option>
                      <option value="2">No</option>
                    </select>
                  </Col>
               </Row>


                  <Row className="mt-3" style={{display:"flex",justifyContent:"end"}}>
                      <button
                        className="fs-5 btn btn-primary nextBtn"
                        style={{backgroundColor:"rgb(23, 160, 160)",color:"black",width:"120px",border:"0px  "}}
                        type="button"
                        onClick={goToPreviousPage}
                      >
                        Previous
                      </button>
                      <button
                     className="fs-5 btn btn-primary nextBtn"
                        style={{backgroundColor:"rgb(23, 160, 160)",color:"black",width:"100px",border:"0px  "}}
                        onClick={handleSubmit}
                      type="submit"
                      >
                        Next
                      </button>
                    </Row>
                </div>
              </div>
            )}

            {currentPage === 4 && (
              <div  id="step-4">
              <div className="mx-2 mx-md-4 px-md-4 px-2 my-4 py-4 shadow" style={{backgroundColor:"rgba(247, 246, 246, 0.781)",borderRadius:"10px"}}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                <h2 className="pt-3" style={{color: 'maroon'}}>Upload Document</h2>
                </div>
           
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={afterlogo} />
                </div>
                <div>

                  <form
                  className="mt-3"
                    method="post"
                    onsubmit="return validateForm()"
                    validate>
                    <Row>
                    <Col md={2}></Col>
                    <Col md={8} >
                    <Row style={{display:"flex"}}>
                    <Row className="mt-md-2 mt-2 " style={{display:"flex"}}>
                    <Col md={6}>
                   <label className="mt-md-3 mt-1">
                        <b>Student's Aadhar card :</b>
                      </label>
                      </Col>
                      <Col md={6}>
                      <input
                        type="file"
                        style={input}
                        className="w-100"
                        onChange={(e) => setAdharCard(e.target.files[0])}
                        accept=".png , .jpeg , .jpg"
                        required
                      />
                      </Col>
                    </Row>
                    
                    <Row className="mt-md-2 mt-2" style={{display:"flex"}}>
                    <Col md={6}>
                   <label className="mt-md-3 mt-1">
                        <b>Student's Photo :</b>
                      </label>
                      </Col>
                      <Col md={6}>
                      <input
                        type="file"
                        className="w-100"
                        name="files"
                        style={input}
                        multiple
                        data-max-file-size="1MB"
                        accept=".png , .jpeg , .jpg"
                        onChange={(e) => setPhoto(e.target.files[0])}
                        data-max-files={3}
                        required
                      />
                      </Col>
                    </Row>

                    <Row className="mt-md-2 mt-2" style={{display:"flex"}}>
                    <Col md={6}>
                   <label className="mt-md-3 mt-1">
                        <b>Student's Last year Marksheet :</b>
                      </label>
                      </Col>
                      <Col md={6}>
                      <input
                        type="file"
                        className="w-100"
                        multiple
                        style={input}
                       data-max-file-size="1MB"
                        accept=".png , .jpeg , .jpg"
                        onChange={(e) => setMarkList(e.target.files[0])}
                        data-max-files={3}
                        required
                      />
                      </Col>
                    </Row>
                    <Row className="mt-md-2 mt-2 " style={{display:"flex"}}>
                    <Col md={6}>
                   <label className="mt-md-3 mt-1">
                        <b>Current Year Admission Receipt :</b>
                      </label>
                      </Col>
                      <Col md={6}>
                      <input
                        type="file"
                        style={input}
                        className="w-100"
                        multiple
                        onChange={(e) => setProficiencyDoc(e.target.files[0])}
                        data-max-file-size="1MB"
                        accept=".png , .jpeg , .jpg"
                        data-max-files={3}
                      />
                      </Col>
                    </Row>
                    </Row>
                    </Col>
                    <Col md={2}></Col>
                    </Row>

                    <Row className="mt-3" style={{display:"flex",justifyContent:"end"}}>
                      {/* <button
                        className="fs-5 btn btn-primary nextBtn"
                        style={{backgroundColor:"rgb(23, 160, 160)",color:"black",width:"120px",border:"0px  "}}
                        type="button"
                        onClick={goToPreviousPage}
                      >
                        Previous
                      </button> */}
                      <button
                       className="fs-5 btn btn-primary nextBtn"
                        style={{backgroundColor:"rgb(23, 160, 160)",color:"black",width:"100px",border:"0px  "}}
                        type="button"
                        onClick={handleup}
                        disabled={!validateFormup()}
                      >
                        Next
                      </button>
                    </Row>
                  </form>
                </div>
              </div>
              </div>
            )}

            {currentPage === 5 && (
              <div id="step-5">
              <div className="mx-2 mx-md-4 px-md-4 px-2 my-4 py-4 shadow" style={{backgroundColor:"rgba(247, 246, 246, 0.781)",borderRadius:"10px"}}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                <h2 className="pt-3" style={{color: 'maroon'}}>Registration Fee</h2>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={afterlogo} />
                </div>
                <div>
                  <form method="post">
                    <div>
                   <h4 style={{color: '#45a049'}} className='mb-3 text-center mt-3'>
                         Scan & Pay Registration Fee.(Rs.50)
                        </h4>
                        <div className="text-center"><img src={qrcode} style={{height:"130px",width:"130px"}} /></div>
                        <div className="text-center">
                        <Button  className='mt-3 mt-md-2' style={{background:"rgb(57, 158, 161)",color:"white",border:"0px"}} onClick= {downloadQRCode}>
                    Download QR Code
                  </Button></div>
                        <Row className="mt-3">
                    <Col md={6} >
                    <div >
                  
                  
                  <Row className="mt-md-2 mt-2 ">
                    <Col md={4}>
                   <label >
                        UPI ID :
                      </label>
                      </Col>
                      <Col md={8}>
                      <p>getepay.svccblqr432819@icici</p>
                      </Col>
                      </Row>

                  <Row className="mt-md-2 mt-2 ">
                    <Col md={4}>
                   <label >
                        Account Name :
                      </label>
                      </Col>
                      <Col md={8}>
                      <p>SHETH RAMDHANAJI DAVADA JAIN BOARDING SANGLI</p>
                      </Col>
                      </Row>

                      <Row className="mt-md-2 mt-2 ">
                    <Col md={4}>
                   <label >
                        Account No :
                      </label>
                      </Col>
                      <Col md={8}>
                      <p>120503130002085</p>
                      </Col>
                      </Row>

                      <Row className="mt-md-2 mt-2 ">
                    <Col md={4}>
                   <label >
                        Bank Name/Branch:
                      </label>
                      </Col>
                      <Col md={8}>
                      <p>SVC CO-OPERATIVE BANK LTD / SANGLI</p>
                      </Col>
                      </Row>

                      <Row className="mt-md-2 mt-2 ">
                    <Col md={4}>
                   <label >
                        IFSC Code :
                      </label>
                      </Col>
                      <Col md={8}>
                      <p>SVCB0000205</p>
                      </Col>
                      </Row>
                </div>
                    </Col>
                    <Col md={6} style={{display:"flex",justifyContent:"center",alignContent:"center"}}>
                    <div>

                      <Row className="mt-md-2 mt-2" style={{ display: "flex" }}>
                        <Col md={6}>
                          <label >Payment Mode :</label>
                        </Col>
                        <Col md={6}>
                          <select
                            id="paymentMode"
                            style={{height:"39px"}}
                            required="required"
                            className={`w-100 rounded ${errors.paymentMode ? "error-input" : ""}`}
                            value={paymentMode}
                            onChange={(e) => setPaymentMode(e.target.value)}
                          >
                            <option value="">Select Payment Mode</option>
                            <option value="1">Online</option>
                            <option value="2">Cash</option>
                          </select>
                          {errors.paymentMode && (
                            <div className="error-message">{errors.paymentMode}</div>
                          )}
                        </Col>
                      </Row>

                        <Row className="mt-md-2 mt-2 " style={{display:"flex"}}>
                    <Col md={6}>
                    {paymentMode === '1'?
                      <label className=' mt-2' htmlFor="transactionId">Transaction ID:</label> :
                      <label className=' mt-2' htmlFor="transactionId">Cash Receipt No:</label>
                    }
                      </Col>
                      <Col md={6}>
                      <input
                        id="mobileNumber"
                        type="text"
                        required="required"
                        className={`w-100 ${
                          errors.taxId ? "error-input" : ""
                        }`}
                        value={taxId}
                        onChange={(e) => setTaxid(e.target.value)}
                    
                        placeholder={paymentMode === '1'?
                        "Enter transaction ID...":
                        "Enter Cash Receipt No..."}
                      />{" "}
                      {errors.taxId && (
                        <div className="error-message">{errors.taxId}</div>
                      )}
                      </Col>
                      </Row>

                      <Row className="mt-md-2 mt-2 " style={{display:"flex"}}>
                    <Col md={6}>
                   <label className="mt-md-3 mt-1">
                          <b>Upload Paid Image :</b>
                        </label>
                        </Col>
                        <Col md={6}>
                        <input
                          type="file"
                          name="files"
                          className="w-100"
                          multiple
                          data-max-file-size="1MB"
                          accept=".png , .jpeg , .jpg"
                          data-max-files={3}
                          onChange={(e) => setTax(e.target.files[0])}
                         
                          required
                        />
                        </Col>
                        </Row>
                      </div>
                      </Col>
                      </Row>
                    </div>

                    <Row className="mt-3" style={{display:"flex",justifyContent:"end"}}>
                      {/* <button
                        className="fs-5 btn btn-primary nextBtn"
                        style={{backgroundColor:"rgb(23, 160, 160)",color:"black",width:"120px",border:"0px  "}}
                        type="button"
                        onClick={goToPreviousPage}
                      >
                        Previous
                      </button> */}
                      <button
                       className="fs-5 btn btn-primary nextBtn"
                        style={{backgroundColor:"rgb(23, 160, 160)",color:"black",width:"100px",border:"0px  "}}
                        type="button"
                        onClick={handleSubmitUpload}
                        disabled={!taxId || !tax || !paymentMode}
                      >
                        Next
                      </button>
                    </Row>

                  </form>
                </div>
              </div>
              </div>
            )}
          </form>

          {currentPage === 6 && (
            <div  id="step-6">
            <div className="mx-2 mx-md-4 px-md-4 px-2 my-4 py-4 shadow" style={{backgroundColor:"rgba(247, 246, 246, 0.781)",borderRadius:"10px"}}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                <h2 className="pt-3" style={{color: 'maroon'}}>Information</h2>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={afterlogo} />
                </div>
              <div >
                <form
                  method="post"
                  onsubmit="return validateForm()"
                  validate
                >
                <Row className="mt-3 mt-md-5">
                  <Col md={3}></Col>
                  <Col md={6}>
                  <ul style={{lineHeight:"35px"}}>
                    <li>बोर्डिंग मेस अनिवार्य आहे व मेस चे सर्व नियम मला मान्य आहेत. </li>
                    <li>
                      येत्या शनिवारी/रविवारी मुलाखतीस यावे.{" "}
                    </li>
                    <li>
                      विद्यार्थ्यास स्वखर्चाने मुलाखतीस यावे लागेल.
                    </li>

                    <li >
                      विद्यार्थ्यांने मुलाखतीस येताना भरलेला अर्ज (PDF) प्रिंट व अपलोड केलेल्या डॉक्युमेंटस ची झेरॉक्स कॉपी घेऊन येणे.{" "}
                    </li>
                    <li >
                      विद्यार्थ्यांस बोर्डिंग मध्ये प्रवेश देण्याचा / नाकारण्याचा अधिकार सर्वस्वी बोर्डिंग मॅनेजमेंटच्या हाती राहील .{" "}
                    </li>
                  </ul>
                  <div className="ms-2">
                    <input
                      type="checkbox"
                      id="agreeCheckbox"
                      onChange={toggleSubmitButton}
                    />
                    <label
                      htmlFor="agreeCheckbox">
                      वरील भरलेली सर्व महिती व कागदपत्रे बरोबर असून मला बोर्डिंगचे सर्व
                      नियम व अटी मान्य आहेत.
                    </label>
                  </div>
                  </Col>
                  <Col md={3}></Col>
                </Row>

                  <Row className="mt-3" style={{display:"flex",justifyContent:"end"}}>
                      {/* <button
                        className="fs-5 btn btn-primary nextBtn"
                        style={{backgroundColor:"rgb(23, 160, 160)",color:"black",width:"120px",border:"0px  "}}
                        type="button"
                        onClick={goToPreviousPage}
                      >
                        Previous
                      </button> */}
                      <button
                       className="fs-5 btn btn-primary nextBtn"
                        style={{backgroundColor:"rgb(23, 160, 160)",color:"black",width:"110px",border:"0px  "}}
                        type="button"
                      onClick={generatePDF}
                      disabled={loading}
                      >
                         {loading ? <Spinner animation='border' size='sm' variant="primary" /> : <div>Submit</div>}
                      </button>
                    </Row>


                </form>
              </div>
              </div>
            </div>
          )}
        </div>

        <footer className="fixed-footer" >
          <div className="container footerr">
            <Row style={{display:"flex",justifyContent:"space-between"}}>
              <Col className="text-center">
                <p>2024 Sheth R. D. Davada Digambar Jain Boarding, Sangli. All Rights Reserved.</p>
              </Col>
              <Col className="text-center">
                <p>2024 Jain Boarding | Managed By : TechnoAarv Solution</p>
              </Col>
            </Row>
          </div>
        </footer>
    
    </section>
    </>
  );
};

export default RegForm;
 