import react, { Fragment, useEffect, useState } from 'react';
import Navbar from '../Components/Navbar';
import './home.css';
import { FaUser, FaGraduationCap, FaStudent, FaBuilding ,FaUserGraduate, FaUsers} from 'react-icons/fa';
import Marquee from './Marquee';
import Footer from '../Components/Footer';
import axios from 'axios';
import Logo from '../.././RoomBookingDesign/img/bedroom.jpg';
import { CarouselImageApi, DirectorPhotoApi, GalleryApi, ImagePath } from '../../ApiFile';



function Home(){

  const [photos, setPhotos] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [guides, setGuides] = useState([]);

  useEffect(() => {
    // Fetch the photos from the API
    axios.get(GalleryApi)
      .then(response => {
        // Limit the photos to the first 8
        setGallery(response.data.slice(0, 8));
      })
      .catch(error => {
        console.error('Error fetching the photos', error);
      });
  }, []);

  useEffect(() => {
    fetch(DirectorPhotoApi)
      .then(response => response.json())
      .then(data => {
        setGuides(data.slice(0, 4));
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
      axios.get(CarouselImageApi)
          .then(response => {
            console.log("fghf",response.data);
              setPhotos(response.data);
          })
          .catch(error => {
              console.error('There was an error fetching the photos!', error);
          });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % photos.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
}, [photos]);

    return(
      <>
      <Navbar/>
      <div>
        {/* Carousel Start */}
        {/* <div className="carousel-header">
          <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
         
            <div className="carousel-inner" role="listbox">
              <div className="carousel-item active">
                <img src="assets/img/boarding img.jpg" className="img-fluid w-100" alt="Image" />
                <div className="carousel-caption">
                  <div className="p-4" style={{maxWidth: '900px'}}>
                    <h1 className="display-2 text-capitalize text-white mb-4" style={{color: 'white', textShadow: '3px 3px darkblue', fontWeight: 'bold'}}>
                    Sheth R. D. Davada Digambar Jain Boarding, Sangli </h1>
                    <span className="mb-5 fs-5"style={{marginLeft:'30px'}}>At Jain Boarding, Every Child Matters!
                    </span>
                   
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <img src="assets/img/wedding.png" className="img-fluid" alt="Image" />
                <div className="carousel-caption">
                  <div className="p-4" style={{maxWidth: '900px'}}>
                    <h1 className="display-2 text-capitalize text-white mb-4" style={{color: 'white', textShadow: '3px 3px darkblue', fontWeight: 'bold'}}>Jain Vadhu-Var Melava</h1>
                    
                    <a className="btn-hover-bg btn btn-primary1 rounded-pill text-white py-3 px-5" href="" style={{backgroundColor: '#ee1515',marginLeft:'-50px'}}>Register Now</a>
                   
                  </div>
                </div>
              </div>
    
            
            </div>
  
         
          </div>
        </div> */}
        {/* Carousel End */}

        <div id="carouselExample" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
          {photos.map((photo, index) => (
            <div className={`carousel-item ${index === currentIndex ? 'active' : ''}`} key={photo.id}>
            <img src={`${ImagePath}/${photo.upload_img}`} style={{height:"100vh"}} className="d-block w-100" alt={`Slide ${index}`} />
                  <div className="carousel-caption d-flex flex-column justify-content-center align-items-center" style={{ height: "100%", top: "50%", transform: "translateY(-50%)" }}>
                      <h1 className="display-2 text-capitalize text-white mb-4" style={{ textShadow: '3px 3px darkblue', fontWeight: 'bold' }}>
                          Sheth R. D. Dawada Digambar Jain Boarding, Sangli
                      </h1>
                      <span className="mb-5 fs-5" style={{ marginLeft: '30px' }}>
                          At Jain Boarding, Every Child Matters!
                      </span>
                  </div>
              </div>
            ))}
          </div>
      </div>

<div className="side-nav">
  <ul>
  <li>
      <a href="/#/StudentLogin" style={{backgroundColor:'#2196f3'}}>
        <FaUser />Hostel
      </a>
    </li>
    <li>
      <a href="https://registration.jainboardingsangli.com" style={{backgroundColor:'#ff6347'}}>
      <i class="fa-solid fa-user-group"></i> Vadhuvar 
      </a>
    </li>
    <li>
      <a href="/#/AluminiLogin" style={{backgroundColor:'#4caf50'}}>
        <FaUserGraduate /> Alumini
      </a>
    </li>
 
    {/* <li>
      <a href="/#/AdminLogin" style={{backgroundColor:'#9c27b0'}}>
        <FaBuilding /> Administration
      </a>
    </li> */}
  </ul>
</div>
      </div>
      {/* Navbar & Hero End */}
      {/* About Start */}
      <div className="container-fluid about py-5">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-5">
              <div className="h-100" style={{border: '1px solid', borderColor: 'transparent #ee1515 transparent #ee1515', boxShadow: 'grey 0px 4px 4px 0px',marginTop:'-80px'}}>
                <img src="assets/img/about.jpg" className="img-fluid w-100 h-100" alt="" />
              </div>
            </div>
            <div className="col-lg-7">
              <h5 className="section-about-title pe-3">About Us</h5>
              <p className="mb-4">Sheth R.D. Dawada Digambar Jain Boarding, Sangli is one of the important part of
                Dakshin Bharat Jain Sabha. It was established on 8th June, 1911. It started its journey at the
                ‘Panjar Pol’ building. In 1918, it purchased its own land &amp; built its own building through
                valuable donation of Sheth Ramchand Dhanaji Dawada (Natepute) on 28th Nov, 1922.</p>
              <p className="mb-4">Historically, importance of Jain Boarding has been increased as a center of Jain
                religion not only in Sangli but also in the Southern region. In the pre-independence period it
                was centre of freedom struggle and hiding place for freedom fighters. Since independence up to
                90’s period of ‘Adv. Viracharya Babasaheb Kuchnure, Jain Boarding had kept its treasure of
                social, economic, religions developments. Till today boarding carrying social responsibilities
                with same sanctity.</p>
              <a className="btn btn-primary rounded-pill py-3 px-5 mt-2" href="/#/About" style={{backgroundColor: '#ee1515'}}>Read More</a>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
      {/* Services Start */}
      <div className="container-fluid bg-light service py-5" data-aos="fade-up" data-aos-delay={400} data-aos-duration={400}>
        <div className="container py-5">
          <div className="mx-auto text-center mb-5" style={{maxWidth: '900px'}}>
          <h5 className="section-title pxh-3 ">Our Services</h5>
          </div>
          <div className="row g-4" data-aos-delay={800} data-aos-duration={400}>
            <div className="col-lg-6">
              <div className="row g-4">
                <div className="col-12" data-aos="fade-up" data-aos-delay={1000} data-aos-duration={400}>
                  <div className="service-content-inner d-flex align-items-center bg-white border border-primary rounded p-4 pe-0">
                    <div className="service-icon p-4">
                      <i className="fa fa-utensil-spoon fa-3x text-primary" />
                    </div>
                    <div className="service-content text-end">
                      <h5 className="mb-4">Annapurna Mess</h5>
                    </div>
                  </div>
                </div>
                <div className="col-12" data-aos="fade-up" data-aos-delay={1300} data-aos-duration={400}>
                  <div className="service-content-inner d-flex align-items-center  bg-white border border-primary rounded p-4 pe-0">
                    <div className="service-icon p-4">
                      <i className="fa fa-hotel fa-3x text-primary" />
                    </div>
                    <div className="service-content text-end">
                      <p className="mb-0">
                      </p><h5 className="mb-4">Library</h5>
                      <p />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row g-4">
                <div className="col-12" data-aos="fade-up" data-aos-delay={1500} data-aos-duration={400}>
                  <div className="service-content-inner d-flex align-items-center bg-white border border-primary rounded p-4 ps-0">
                    <div className="service-icon p-4">
                      <i className="fa fa-dumbbell fa-3x text-primary" />
                    </div>
                    <div className="service-content">
                      <p className="mb-0">
                      </p><h5 className="mb-4">Gym</h5>
                      <p />
                    </div>
                  </div>
                </div>
                <div className="col-12" data-aos="fade-up" data-aos-delay={1700} data-aos-duration={400}>
                  <div className="service-content-inner d-flex align-items-center bg-white border border-primary rounded p-4 ps-0">
                    <div className="service-icon p-4">
                      <i className="fas fa-door-open fa-3x text-primary" />
                    </div>
                    <div className="service-content">
                      <p className="mb-0">
                      </p><h5 className="mb-4">Rooms</h5>
                      <p />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Packages End */}
      {/* Travel Guide Start */}
      <section className="services">
        <div className="container">
          <div className=" guide py-5">
            <div className=" py-5">
              <div className="mx-auto text-center mb-5" >
                <h5 className="section-title pxh-3">Board of Directors</h5>
              </div>
              {/* <div className="container photo">
              <div className="row g-4">
                <div className="col-md-6 col-lg-3">
                  <div className="guide-item">
                    <div className="guide-img">
                      <div className="guide-img-efects">
                        <img src="assets/img/Rahul Chougule.jpg" className="img-fluid w-100 rounded-top" alt="Image" />
                      </div>
                   
                    </div>
                    <div className="guide-title text-center rounded-bottom p-4">
                      <div className="guide-title-inner">
                        <h4 className="mt-0" style={{fontSize:'19px'}}>Mr.Rahul Chougule</h4>
                        <p className="mb-0" style={{textAlign:'center'}}>Chairman</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="guide-item">
                    <div className="guide-img">
                      <div className="guide-img-efects">
                        <img src="img/guide-2.jpg" className="img-fluid w-100 rounded-top" alt="Image" />
                      </div>
                     
                    </div>
                    <div className="guide-title text-center rounded-bottom p-4">
                      <div className="guide-title-inner">
                      <h4 className="mt-0" style={{fontSize:'19px'}}>Mr.Rahul Chougule</h4>
                        <p className="mb-0" style={{textAlign:'center'}}>Chairman</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="guide-item">
                    <div className="guide-img">
                      <div className="guide-img-efects">
                        <img src="img/guide-3.jpg" className="img-fluid w-100 rounded-top" alt="Image" />
                      </div>
                     
                    </div>
                    <div className="guide-title text-center rounded-bottom p-4">
                      <div className="guide-title-inner">
                      <h4 className="mt-0" style={{fontSize:'19px'}}>Mr.Rahul Chougule</h4>
                        <p className="mb-0" style={{textAlign:'center'}}>Chairman</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="guide-item">
                    <div className="guide-img">
                      <div className="guide-img-efects">
                        <img src="img/guide-4.jpg" className="img-fluid w-100 rounded-top" alt="Image" />
                      </div>
                      
                    </div>
                    <div className="guide-title text-center rounded-bottom p-4">
                      <div className="guide-title-inner">
                      <h4 className="mt-0" style={{fontSize:'19px'}}>Mr.Rahul Chougule</h4>
                        <p className="mb-0" style={{textAlign:'center'}}>Chairman</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div> */}
              <div className="photo">
                <div className="row ">
                  {guides.map((guide, index) => (
                    <div className="col-md-6 col-lg-3 mt-4" key={index}>
                      <div className="guide-item">
                        <div className="guide-img">
                          <div className="guide-img-efects">
                            <img
                              src={`${ImagePath}/${guide.upload_img}`}
                              className="img-fluid w-100 rounded-top"
                              style={{height:"210px"}}
                              alt={guide.upload_img}
                            />
                          </div>
                        </div>
                        <div className="guide-title text-center rounded-bottom p-4">
                          <div className="guide-title-inner">
                          <h4 className="mt-0" style={{ fontSize: '19px' }}>
                    {`${guide.fname} ${guide.mname} ${guide.lname}`}
                  </h4>

                            <p className="mb-0" style={{ textAlign: 'center' }}>
                            {guide.designation}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='text-center mt-3'>
              <a className="btn btn-primary rounded-pill py-2 px-3 mt-2 text-center" href="/#/Boardofdir"  style={{backgroundColor: '#ee1515', width: '200px',marginLeft:'-10px'}}>View More</a></div>
              {/* Gallery Start */}
              <div className="container-fluid gallery py-5 my-5">
                <div className="mx-auto text-center mb-5" style={{maxWidth: '900px'}}>
                  <h5 className="section-title pxh-3">Our Gallery</h5>
                </div>
                <div className="tab-class text-center">
                  <div className="tab-content">
                    <div id="GalleryTab-1" className="tab-pane fade show p-0 active">
                      {/* <div className="row g-2">
                        <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                          <div className="gallery-item h-100">
                            <img src="assets/img/g1.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                            <div className="gallery-content">
                            </div>
                            <div className="gallery-plus-icon">
                              <a href="assets/img/g1.jpg" data-lightbox="gallery-1" className="my-auto"><i className="fas fa-plus fa-2x text-white" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                          <div className="gallery-item h-100">
                            <img src="assets/img/post1.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                            <div className="gallery-plus-icon">
                              <a href="assets/img/post1.jpg" data-lightbox="gallery-2" className="my-auto"><i className="fas fa-plus fa-2x text-white" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                          <div className="gallery-item h-100">
                            <img src="assets/img/post2.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                            <div className="gallery-content">
                            </div>
                            <div className="gallery-plus-icon">
                              <a href="assets/img/post2.jpg" data-lightbox="gallery-3" className="my-auto"><i className="fas fa-plus fa-2x text-white" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                          <div className="gallery-item h-100">
                            <img src="assets/img/post3.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                            <div className="gallery-content">
                            </div>
                            <div className="gallery-plus-icon">
                              <a href="assets/img/post3.jpg" data-lightbox="gallery-4" className="my-auto"><i className="fas fa-plus fa-2x text-white" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                          <div className="gallery-item h-100">
                            <img src="assets/img/post4.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                            <div className="gallery-content">
                            </div>
                            <div className="gallery-plus-icon">
                              <a href="assets/img/post4.jpg" data-lightbox="gallery-5" className="my-auto"><i className="fas fa-plus fa-2x text-white" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                          <div className="gallery-item h-100">
                            <img src="assets/img/post5.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                            <div className="gallery-content">
                            </div>
                            <div className="gallery-plus-icon">
                              <a href="assets/img/post5.jpg" data-lightbox="gallery-6" className="my-auto"><i className="fas fa-plus fa-2x text-white" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                          <div className="gallery-item h-100">
                            <img src="assets/img/post6.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                            <div className="gallery-content">
                            </div>
                            <div className="gallery-plus-icon">
                              <a href="assets/img/post6.jpg" data-lightbox="gallery-7" className="my-auto"><i className="fas fa-plus fa-2x text-white" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                          <div className="gallery-item h-100">
                            <img src="assets/img/post7.jpg" className="img-fluid w-100 h-100 rounded" alt="Image" />
                            <div className="gallery-content">
                            </div>
                            <div className="gallery-plus-icon">
                              <a href="assets/img/post7.jpg" data-lightbox="gallery-8" className="my-auto"><i className="fas fa-plus fa-2x text-white" /></a>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="row g-2">
      {gallery.map((gallery, index) => (
        <div key={gallery.id} className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
          <div className="gallery-item h-100">
            <img
              src={`${ImagePath}/${gallery.upload_img}`}
              className="img-fluid w-100 h-100 rounded"
              alt={`Gallery ${index + 1}`}
            />
            <div className="gallery-plus-icon">
              <a href={`${ImagePath}/${gallery.upload_img}`} data-lightbox={`gallery-${index + 1}`} className="my-auto">
                <i className="fas fa-plus fa-2x text-white" />
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>

                      <br />
                      <a className="btn btn-primary rounded-pill py-2 px-3 mt-2" href="/#/Gallery" style={{backgroundColor: '#ee1515', width: '200px',marginLeft:'-10px'}}>View More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div></section>
      {/* Gallery End */}
      {/* Tour Booking Start */}
      {/* Tour Booking End */}
      {/* Travel Guide End */}
      {/* Blog Start */}
      {/* Blog End */}
      {/* Testimonial Start */}
      {/* Testimonial End */}
      {/* Subscribe Start */}
      {/* Subscribe End */}
      {/* Footer Start */}
      <Footer />
          {/* Copyright End */}
      {/* Back to Top */}
      {/* <a href="#" className="btn btn-primary btn-primary-outline-0 btn-md-square back-to-top"><i className="fa fa-arrow-up" /></a> */}
      {/* JavaScript Libraries */}
      {/* Template Javascript */}
      {/* <Marquee/> */}
    
    </>
    )
}
export default Home;