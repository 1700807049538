import React, { useEffect, useState } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { AiOutlineForm, AiOutlineLogout } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import "./sidebar.css";

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();
  
  useEffect(() => {
    let sessionData =  sessionStorage.getItem('member_id');
if(!sessionData){
  navigate('/')
}
  
  }, [])

  const logoutFun = () => {
    navigate("/StudentLogin");
  };

  return (
    <div
      className={`sidebar ${isOpen ? "open" : ""}`}
      style={{
        display: "flex",
        flexDirection: "column",
        flex: "1",
        overflow: "scroll",
      }}
    >
      <div className="logo-details mt-4">
        <i className="bx bxs-landscape"></i>
        <div
          className="logo_name"
          style={{
            marginLeft: "-32px",
            color: "maroon",
            textShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            fontSize: "24px",
            fontWeight: "bold",
            letterSpacing: "1px",
            fontFamily: "Arial, sans-serif",
          }}
        >
         Room Booking
        </div>
        <i
          className={`fa ${isOpen ? "fa-solid fa-bars" : "fa-solid fa-bars"}`}
          id="btn"
          onClick={toggleSidebar}
        ></i>
      </div>
      <div className="sidebar-content">
        <ul className="nav-list mb-5 ">
          {/* <li>
            <a >
              <i className="fa fa-line-chart"></i>
              <span className="links_name">Dashboard</span>
            </a>
          </li> */}

          <li >
                <a href="/#/BlockRoomBooking" style={{paddingRight:"90px"}}>
                <i className="fa-solid fa-house-user"></i>
                <span className="links_name">Room Booking</span> 
                </a>
          </li>

          {/* <li >
                <a >
                <i class="fa-solid fa-calendar"></i>
                <span className="links_name">Events</span> 
                </a>
          </li> */}

          {/* <li >
                <a >
                <i class="fa-solid fa-file-lines"></i>
                <span className="links_name">Compaint form</span> 
                </a>
          </li> */}

          {/* <li >
                <a >
                <i class="fa-solid fa-folder-plus"></i>
                <span className="links_name">Jobs</span> 
                </a>
          </li> */}

          {/* <li>
            <a onClick={logoutFun} style={{paddingRight:"90px"}}>
              <i class="fa-solid fa-circle-arrow-left"></i>
              <span className="links_name">Log Out</span>
            </a>
          </li> */}
        
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
