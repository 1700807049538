import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { MDBDataTable } from 'mdbreact';
import { Col, Row } from 'react-bootstrap';
import NavLogo from '../NavLogo';
import { ImagePath, AluminiFindOne, AluminiFindAll } from '../../ApiFile';
import Sidebar from '../sidebar';

export default function AluminiList() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({}); // Initialize as an empty object to avoid undefined errors

  const fetchData = () => {
    axios
      .get(AluminiFindAll)
      .then((response) => {
        const mappedRows = response.data.map((row, index) => ({
          ...row,
          Name: row.name,
          View: (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleViewClick(row)}
              style={{ fontSize: '12px' }}
            >
              <span className="me-1">View </span>
              <i className="fa-regular fa-eye"></i>
            </Button>
          ),
        }));
        setRows(mappedRows);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleViewClick = (row) => {
    setSelectedRow(row || {});
    setOpen(true);

    axios
      .get(`${AluminiFindOne}/${row.id}`)
      .then((response) => {
        setSelectedRow(response.data[0]);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const data = {
    columns: [
      { label: 'Sr.No', field: 'srNo' },
      { label: 'Name', field: 'name', width: 150 },
      { label: 'View', field: 'View' },
    ],
    rows: rows.map((row, index) => ({
      srNo: index + 1,
      ...row, 
    })),
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <NavLogo />
        <div className="block-header">
          <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
            Alumini List
          </h2>
        </div>

        <Row className="m-0 p-0 px-md-3 py-1 pb-5 mb-5 mt-4 w-100" style={{ display: 'flex' }}>
          <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
            <div style={{ overflowY: 'auto' }}>
              <div style={{ overflowX: 'auto' }}>
                <MDBDataTable
                  striped
                  className="custom-datatable text-center"
                  bordered
                  hover
                  small
                  data={data}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="status-modal-title"
          aria-describedby="status-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: '700px',
              maxHeight: '90vh',
              overflow: 'scroll',
              backgroundColor: '#fff',
              borderRadius: '10px',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ overflowY: 'auto', p: 4, flex: 1 }}>
              <Typography id="modal-modal-description" style={{ lineHeight: '18px' }}>
                <div className="block-header">
                  <h2
                    className="mb-3"
                    style={{ color: 'maroon', borderBottom: '2px solid maroon' }}
                  >
                    Alumini List
                  </h2>
                </div>
                <Row>
                  <Col>
                    <b>Name</b>:
                  </Col>
                  <Col>{selectedRow.f_name} {selectedRow.m_name}  {selectedRow.l_name}</Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <b>Current Address</b>:
                  </Col>
                  <Col>{selectedRow.current_address} {selectedRow.taluka} {selectedRow.district} {selectedRow.state} {selectedRow.pincode}</Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <b>Alternate Address</b>:
                  </Col>
                  <Col>{selectedRow.alternate_address} {selectedRow.alternate_taluka} {selectedRow.alternate_district} {selectedRow.alternate_state} {selectedRow.alternate_pincode}</Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <b>Mobile No.</b>:
                  </Col>
                  <Col>{selectedRow.mob_no}</Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <b>Alternate Mobile No.</b>:
                  </Col>
                  <Col>{selectedRow.alternate_mobno}</Col>
                </Row>
                <br />
               
                <Row>
                  <Col>
                    <b>Qualification</b>:
                  </Col>
                  <Col>{selectedRow.qualification}</Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <b>Date Of Birth</b>:
                  </Col>
                  <Col>{selectedRow.dateofbirth}</Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <b>Blood Group</b>:
                  </Col>
                  <Col>{selectedRow.blood_group}</Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <b>Email</b>:
                  </Col>
                  <Col>{selectedRow.email_id}</Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <b>Professional Category</b>:
                  </Col>
                  <Col>{selectedRow.professional_category}</Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <b>Business Name</b>:
                  </Col>
                  <Col>{selectedRow.business_service_name}</Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <b>From Year Of Boarding</b>:
                  </Col>
                  <Col>{selectedRow.fromyearof_boarding}</Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <b>To Year Of Boarding</b>:
                  </Col>
                  <Col>{selectedRow.toyearof_boarding}</Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <b>Reference Name</b>:
                  </Col>
                  <Col>{selectedRow.reference_fname} {selectedRow.reference_mname} {selectedRow.reference_lname}</Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <b>Reference Contact Details</b>:
                  </Col>
                  <Col>{selectedRow.referenceother_contactdetails}</Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <b>Achievements</b>:
                  </Col>
                  <Col>{selectedRow.achievements}</Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <b>Suggestion Comment</b>:
                  </Col>
                  <Col>{selectedRow.suggestion_comment}</Col>
                </Row>
                <br />
                <Row style={{ display: 'flex' }}>
                  <Col>
                    <b>Profile Image</b>:
                  </Col>
                  <Row>
                    <Col md={3}></Col>
                    <Col className="mt-2 text-center" md={6}>
                      <img
                        src={`${ImagePath}/${selectedRow.profile_img}`}
                        className="acceptable rounded shadow border-0"
                        alt=""
                        style={{
                          height: '16rem',
                          width: '15rem',
                          border: '1px solid grey',
                        }}
                      />
                    </Col>
                    <Col md={3}></Col>
                  </Row>
                </Row>
              </Typography>
            </Box>
            <Box
              sx={{
                p: 2,
                borderTop: '1px solid #ccc',
                textAlign: 'right',
              }}
            >
              <Button variant="contained" color="primary" onClick={() => setOpen(false)}>
                Close
              </Button>
            </Box>
          </Box>
        </Modal>
      </section>
    </>
  );
}
