import React, { useState, useEffect } from "react";
import { Accordion, useAccordionButton } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "./sidebar.css";

function Sidebar() {
  const [isOpen, setIsOpen] = useState(true);
  const [activeKey, setActiveKey] = useState(null);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();

  useEffect(() => {
    let sessionData =  sessionStorage.getItem('email');
    let flag =  sessionStorage.getItem('flag');
if(!sessionData || !flag){
  navigate('/')
}
  
  }, [])

  let flag = Number(sessionStorage.getItem("flag"));

  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      setActiveKey(activeKey === eventKey ? null : eventKey);
    });

    return (
      <span
        onClick={decoratedOnClick}
        style={{
          color: "#495494",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
      >
        {children}
      </span>
    );
  };

  useEffect(() => {
    // Set the active key based on the current path
    if (location.pathname.startsWith("/Block") || location.pathname.startsWith("/room") || location.pathname.startsWith("/Bed")) {
      setActiveKey("2");
    }
    else if(location.pathname.startsWith("/FeeType") || location.pathname.startsWith("/FeeMaster1")){
      setActiveKey("0");
    }
    else if(location.pathname.startsWith("/Reg50Approval") || location.pathname.startsWith("/StudentView") || location.pathname.startsWith("/RoomApproval") || location.pathname.startsWith("/payment") || location.pathname.startsWith("/JobApproval") || location.pathname.startsWith("/RegularAndEventLeaveApprove") || location.pathname.startsWith("/MessLeaveApprove") || location.pathname.startsWith("/EventLeaveApprove")){
      setActiveKey("1");
    }
    else if(location.pathname.startsWith("/JobCategoryMaster") || location.pathname.startsWith("/Jobpost") || location.pathname.startsWith("/Alljob") || location.pathname.startsWith("/payment")){
      setActiveKey("3");
    }
    else if(location.pathname.startsWith("/BoardingDirectorsImgMaster") || location.pathname.startsWith("/WebsiteDashboardImgMaster") || location.pathname.startsWith("/GalleryMaster")){
      setActiveKey("4");
    }
    else if(location.pathname.startsWith("/event") || location.pathname.startsWith("/EventAttendance") ){
      setActiveKey("5");
    }
    else if(location.pathname.startsWith("/RequestRec") || location.pathname.startsWith("/MessLeaveApproval") || location.pathname.startsWith("/MessAttendanceAdmin")|| location.pathname.startsWith("/FeedBack")|| location.pathname.startsWith("/BreakfastmenuMaster") || location.pathname.startsWith("/MessMenuMaster")){
      setActiveKey("6");
    }
  }, [location]);

  return (
    <div
      className={`sidebar ${isOpen ? "open" : ""}`}
      style={{
        display: "flex",
        flexDirection: "column",
        flex: "1",
        overflow: "scroll",
      }}
    >
      <div className="logo-details mt-4">
        <i className="bx bxs-landscape"></i>
        <div
          className="logo_name ms-0"
          style={{
            color: "White",
            textShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            fontSize: "24px",
            fontWeight: "bold",
            letterSpacing: "1px",
            fontFamily: "Arial, sans-serif",
          }}
        >
          Wel Come{" "}
        </div>
        <i
          className={`fa ${isOpen ? "fa-solid fa-bars" : "fa-solid fa-bars"}`}
          id="btn"
          onClick={toggleSidebar}
        ></i>
      </div>
      <div className="sidebar-content">
        <ul className="nav-list">

        {(flag == 1 || flag == 3 || flag == 2 || flag == 4) && (
          <li>
            <a
              href="/#/HomeAdmin"
              className="active"
              style={{ backgroundColor: "transparent" }}
            >
              <i className="fa fa-line-chart"></i>
              <span className="links_name">Dashboard</span>
            </a>
            <span className="tooltip">Dashboard</span>
          </li>
        )}

          {(flag == 1 || flag == 3) && (
            <li>
              <a href="/#/Rule" style={{ backgroundColor: "transparent" }}>
                <i className="fa-solid fa-comments"></i>
                <span className="links_name">Admission Rule Master</span>
              </a>
              <span className="tooltip">Admission Rules</span>
            </li>
          )}

          {(flag == 1 || flag == 3) && (
            <li>
              <a href="/#/Highlight" style={{ backgroundColor: "transparent" }}>
              <i class="fa-regular fa-snowflake"></i>
                <span className="links_name">Highlight Master</span>
              </a>
              <span className="tooltip">Highlight Master</span>
            </li>
          )}

          {(flag == 1 || flag == 3) && (
          <Accordion activeKey={activeKey}>
            <li>
              <CustomToggle eventKey="0">
                <i className="fa-solid fa-sack-dollar"></i>
                <span className="links_name" style={{ color: "black" }}>
                  Fee Master
                </span>
                <span
                  className="arrow-icon"
                  style={{ marginLeft: "auto", color: "black" }}
                >
                  <>&#9660;</>
                </span>
              </CustomToggle>
              <Accordion.Collapse eventKey="0">
                <ul className="dropdown-list " style={{ listStyle: "none" }}>
                  <li>
                    <a
                    className="ps-2 text-start"
                      href="/#/FeeType"
                      style={{
                        textDecoration: "none",
                        backgroundColor: "transparent",
                      }}
                    >
                      <i className="fa-solid fa-indian-rupee-sign"></i>
                      <span className="links_name">Fee Type Master</span>
                    </a>
                  </li>
                  <li>
                    <a
                     className="ps-2 text-start"
                      href="/#/FeeMaster1"
                      style={{
                        textDecoration: "none",
                        backgroundColor: "transparent",
                      }}
                    >
               <i class="fa-solid fa-rupee-sign"></i>
                      <span className="links_name">Fees Amount Entry</span>
                    </a>
                  </li>
                </ul>
              </Accordion.Collapse>
            </li>
          </Accordion>
          )}

          {(flag == 1 || flag == 3) && (
          <Accordion activeKey={activeKey}>
            <li>
              <CustomToggle eventKey="2" className="master">
              <i class="fa-solid fa-house-medical"></i>
                <span className="links_name" style={{ color: "black" }}>
                  Room Master
                </span>
                <span
                  className="arrow-icon"
                  style={{ marginLeft: "auto", color: "black" }}
                >
                  <>&#9660;</>
                </span>
              </CustomToggle>
              <Accordion.Collapse eventKey="2">
                <ul className="dropdown-list" style={{ listStyle: "none" }}>
                  <li>
                    <a
                     className="ps-2 text-start"
                      href="/#/Block"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <i className="fa-solid fa-building"></i>
                      <span className="links_name">Block</span>
                    </a>
                  </li>
                  <li>
                    <a
                     className="ps-2 text-start"
                      href="/#/room"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <i className="fa-solid fa-house-user"></i>
                      <span className="links_name">Room</span>
                    </a>
                  </li>
                  <li>
                    <a  className="ps-2 text-start" href="/#/Bed" style={{ backgroundColor: "transparent" }}>
                      <i className="fa-solid fa-bed"></i>
                      <span className="links_name">Bed</span>
                    </a>
                  </li>
                </ul>
              </Accordion.Collapse>
            </li>
          </Accordion>
          )}

          
          <Accordion activeKey={activeKey}>
            <li>
            {(flag == 1 || flag == 3 || flag == 2) && (
              <CustomToggle eventKey="1" className="master">
                <i className="fa-solid fa-person-circle-check"></i>
                <span className="links_name" style={{ color: "black" }}>
                  Approvals
                </span>
                <span
                  className="arrow-icon"
                  style={{ marginLeft: "auto", color: "black" }}
                >
                  <>&#9660;</>
                </span>
              </CustomToggle>
            )}
              <Accordion.Collapse eventKey="1">
                <ul className="dropdown-list " style={{ listStyle: "none" }}>

                {(flag == 1 || flag == 3) && (
                  <li>
                    <a
                     className="ps-2 text-start"
                      href="/#/Reg50Approval"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <i className="fa-solid fa-file"></i>
                      <span className="links_name">Application Approval</span>
                    </a>
                  </li>
                )}

                {(flag == 1 || flag == 2) && (
                  <li>
                    <a
                     className="ps-2 text-start"
                      href="/#/StudentView"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <i className="fa-solid fa-user"></i>
                      <span className="links_name">Member Approval</span>
                    </a>
                  </li>
                )}

                  {/* <li>
                    <a
                     className="ps-2 text-start"
                      href="/#/RoomApproval"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <i className="fa-solid fa-bed"></i>
                      <span className="links_name">Room Approval</span>
                    </a>
                  </li> */}

                  {(flag == 1 || flag == 3) && (
                  <li>
                    <a
                     className="ps-2 text-start"
                      href="/#/payment"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <i className="fa-solid fa-credit-card"></i>
                      <span className="links_name">Payment Approval</span>
                    </a>
                  </li>
                  )}

                  {(flag == 1 || flag == 3) && (
                  <li>
                    <a
                     className="ps-2 text-start"
                      href="/#/JobApproval"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <i className="fa-solid fa-bag-shopping"></i>
                      <span className="links_name">Job Approval</span>
                    </a>
                  </li>
                  )}

                  {(flag == 1 || flag == 2) && (
                  <li>
                    <a
                     className="ps-2 text-start"
                      href="/#/RegularAndEventLeaveApprove"
                      style={{ backgroundColor: "transparent" }}
                    >
                        <i class="fa-solid fa-newspaper"></i>
                      <span className="links_name">Regular Leave Approval</span>
                    </a>
                  </li>
                  )}

                  {(flag == 1 || flag == 2) && (
                  <li>
                    <a
                     className="ps-2 text-start"
                      href="/#/EventLeaveApprove"
                      style={{ backgroundColor: "transparent" }}
                    >
                         <i class="fa-solid fa-pager"></i>
                      <span className="links_name">Event Leave Approval</span>
                    </a>
                  </li>
                  )}

                  {(flag == 1 ) && (
                  <li>
                    <a
                     className="ps-2 text-start"
                      href="/#/MessLeaveApprove"
                      style={{ backgroundColor: "transparent" }}
                    >
                  <i class="fa-solid fa-bowl-food"></i>
                      <span className="links_name">Meals Leave Approval</span>
                    </a>
                  </li>
                  )}
                </ul>
              </Accordion.Collapse>
            </li>
          </Accordion>

          {(flag == 1 ) && (
                  <li>
                    <a
                     className="text-start"
                      href="/#/MessLeaveApprove"
                      style={{ backgroundColor: "transparent" }}
                    >
                  <i class="fa-solid fa-bowl-food"></i>
                      <span className="links_name">Meals Leave Approval</span>
                    </a>
                  </li>
                  )}

          {(flag == 2 ) && (
                 <li>
                    <a
                    className="ps-2 text-start"
                      href="/#/MessMenuMaster"
                      style={{
                        textDecoration: "none",
                        backgroundColor: "transparent",
                      }}
                    >
                     <i class="fa-solid fa-plate-wheat"></i>
                      <span className="links_name">Menu List</span>
                    </a>
                  </li>
          )}

          {(flag == 1 || flag == 4) && (
          <Accordion activeKey={activeKey}>
            <li>
              <CustomToggle eventKey="6">
              <i class="fa-solid fa-bowl-rice"></i>
                <span className="links_name" style={{ color: "black" }}>
                  Mess
                </span>
                <span
                  className="arrow-icon"
                  style={{ marginLeft: "auto", color: "black" }}
                >
                  <>&#9660;</>
                </span>
              </CustomToggle>
              <Accordion.Collapse eventKey="6">
                <ul className="dropdown-list " style={{ listStyle: "none" }}>
                  <li>
                    <a
                    className="ps-2 text-start"
                      href="/#/MessMenuMaster"
                      style={{
                        textDecoration: "none",
                        backgroundColor: "transparent",
                      }}
                    >
                     <i class="fa-solid fa-plate-wheat"></i>
                      <span className="links_name">Menu Master</span>
                    </a>
                  </li>
                  <li>
                    <a
                     className="ps-2 text-start"
                      href="/#/BreakfastmenuMaster"
                      style={{
                        textDecoration: "none",
                        backgroundColor: "transparent",
                      }}
                    >
          <i class="fa-solid fa-hot-tub-person"></i>
                      <span className="links_name">BreakFast Master</span>
                    </a>
                  </li>
                  <li>
                    <a
                     className="ps-2 text-start"
                      href="/#/MessAttendanceAdmin"
                      style={{
                        textDecoration: "none",
                        backgroundColor: "transparent",
                      }}
                    >
       
            <i class="fa-solid fa-clipboard-user"></i>
                      <span className="links_name">Attendance</span>
                    </a>
                  </li>
                  <li>
                    <a
                     className="ps-2 text-start"
                      href="/#/MessLeaveApproval"
                      style={{
                        textDecoration: "none",
                        backgroundColor: "transparent",
                      }}
                    >
                  <i class="fa-solid fa-bowl-food"></i>
                      <span className="links_name">Mess Leave Approval</span>
                    </a>
                  </li>
                  <li>
                    <a
                     className="ps-2 text-start"
                      href="/#/RequestRec"
                      style={{
                        textDecoration: "none",
                        backgroundColor: "transparent",
                      }}
                    >
    <i class="fa-solid fa-bell-concierge"></i>
                      <span className="links_name">Breakfast Request</span>
                    </a>
                  </li>
                  <li>
                    <a
                     className="ps-2 text-start"
                      href="/#/FeedBack"
                      style={{
                        textDecoration: "none",
                        backgroundColor: "transparent",
                      }}
                    >
              <i class="fa-solid fa-pager"></i>
                      <span className="links_name">Feedback</span>
                    </a>
                  </li>
                </ul>
              </Accordion.Collapse>
            </li>
          </Accordion>
          )}

          {(flag == 1 || flag == 2) && (
          <li>
            <a href="/#/RoomChange" style={{ backgroundColor: "transparent" }}>
            <i class="fa-solid fa-house-user"></i>
              <span className="links_name">Room Change</span>
            </a>
          </li>
          )}

          {(flag == 1 || flag == 3 || flag == 2 ) && (
          <li>
            <a href="/#/complaint" style={{ backgroundColor: "transparent" }}>
              <i className="fa-solid fa-file-lines"></i>
              <span className="links_name">Complaints</span>
            </a>
          </li>
          )}

        
          <Accordion activeKey={activeKey}>
            <li>
            {(flag == 1 || flag == 3 || flag == 2) && (
              <CustomToggle eventKey="5">
              <i className="fa-solid fa-calendar-days"></i>
                <span className="links_name" style={{ color: "black" }}>
                 Event
                </span>
                <span
                  className="arrow-icon"
                  style={{ marginLeft: "auto", color: "black" }}
                >
                  <>&#9660;</>
                </span>
              </CustomToggle>
            )}
              <Accordion.Collapse eventKey="5">
                <ul className="dropdown-list" style={{ listStyle: "none" }}>

                {(flag == 1 || flag == 3 || flag ==2) && (
                  <li>
                    <a
                     className="ps-2"
                      href="/#/event"
                      style={{
                        textDecoration: "none",
                        color: "#495494",
                        backgroundColor: "transparent",
                      }}
                    >
                    <i className="fa-solid fa-calendar-days"></i>
                      <span className="links_name"> {flag === 1 ? 'Add Events' : flag === 2 ? 'Event List' : flag === 3 ? 'Event List' : flag === 4 ? 'Event List' : null}</span>
                    </a>
                  </li>
                )}

                {(flag == 1 || flag == 2) && (
                  <li>
                    <a
                     className="ps-2"
                    href="/#/EventAttendance"
                      style={{
                        textDecoration: "none",
                        color: "#495494",
                        backgroundColor: "transparent",
                      }}
                    >
               <i class="fa-solid fa-clipboard-user"></i>
                      <span className="links_name">Event Attendance</span>
                    </a>
                  </li>
                )}

                </ul>
              </Accordion.Collapse>
            </li>
          </Accordion>

          
          <Accordion activeKey={activeKey}>
            <li>
            {(flag == 1 || flag == 3 || flag == 2) && (
              <CustomToggle eventKey="3">
                <i className="fa-solid fa-bag-shopping"></i>
                <span className="links_name" style={{ color: "black" }}>
                  Jobs
                </span>
                <span
                  className="arrow-icon"
                  style={{ marginLeft: "auto", color: "black" }}
                >
                  <>&#9660;</>
                </span>
              </CustomToggle>
            )}
              <Accordion.Collapse eventKey="3">
                <ul className="dropdown-list" style={{ listStyle: "none" }}>

                {(flag == 1 || flag == 3) && (
                  <li>
                    <a
                     className="ps-2"
                      href="/#/JobCategoryMaster"
                      style={{
                        textDecoration: "none",
                        color: "#495494",
                        backgroundColor: "transparent",
                      }}
                    >
                      <i className="fa-solid fa-layer-group"></i>
                      <span className="links_name">Job Category Master</span>
                    </a>
                  </li>
                )}

                {(flag == 1 || flag == 3 || flag == 2) && (
                  <li>
                    <a
                     className="ps-2"
                      href="/#/JobpostAdmin"
                      style={{
                        textDecoration: "none",
                        color: "#495494",
                        backgroundColor: "transparent",
                      }}
                    >
                      <i className="fa-solid fa-folder-plus"></i>
                      <span className="links_name">Job Post</span>
                    </a>
                  </li>
                )}

                {(flag == 1 || flag == 3 || flag == 2) && (
                  <li>
                    <a
                     className="ps-2"
                      href="/#/Alljob"
                      style={{
                        textDecoration: "none",
                        color: "#495494",
                        backgroundColor: "transparent",
                      }}
                    >
                      <i className="fa-solid fa-list"></i>
                      <span className="links_name">All Job Data</span>
                    </a>
                  </li>
                )}
                </ul>
              </Accordion.Collapse>
            </li>
          </Accordion>

          {(flag == 1 || flag == 3) && (
          <Accordion activeKey={activeKey}>
            <li>
              <CustomToggle eventKey="4">
              <i className="fa-solid fa-image"></i>
                <span className="links_name" style={{ color: "black" }}>
                Gallery Master
                </span>
                <span
                  className="arrow-icon"
                  style={{ marginLeft: "auto", color: "black" }}
                >
                  <>&#9660;</>
                </span>
              </CustomToggle>
              <Accordion.Collapse eventKey="4">
                <ul className="dropdown-list" style={{ listStyle: "none" }}>
                  <li>
                    <a
                     className="ps-2"
                      href="/#/WebsiteDashboardImgMaster"
                      style={{
                        textDecoration: "none",
                        color: "#495494",
                        backgroundColor: "transparent",
                      }}
                    >
                      <i className="fa-solid fa-layer-group"></i>
                      <span className="links_name">Website Dashboard</span>
                    </a>
                  </li>
                  <li>
                    <a
                     className="ps-2"
                      href="/#/BoardingDirectorsImgMaster"
                      style={{
                        textDecoration: "none",
                        color: "#495494",
                        backgroundColor: "transparent",
                      }}
                    >
                      <i class="fa-solid fa-user"></i>
                      <span className="links_name">Boarding Director </span>
                    </a>
                  </li>
                  <li>
                    <a
                     className="ps-2"
                      href="/#/GalleryMaster"
                      style={{
                        textDecoration: "none",
                        color: "#495494",
                        backgroundColor: "transparent",
                      }}
                    >
                      <i class="fa-solid fa-image"></i>
                      <span className="links_name">Gallery Master</span>
                    </a>
                  </li>
                </ul>
              </Accordion.Collapse>
            </li>
          </Accordion>
          )}

          {(flag == 2) && (
          <li>
                    <a
                     className="ps-2"
                      href="/#/GalleryMaster"
                      style={{
                        textDecoration: "none",
                        color: "#495494",
                        backgroundColor: "transparent",
                      }}
                    >
                      <i class="fa-solid fa-image"></i>
                      <span className="links_name">Gallery Master</span>
                    </a>
                  </li>
          )}

          {(flag == 1) && (
          <li>
            <a 
            href="/#/ToDoMainAdmin"
             style={{ backgroundColor: "transparent" }}>
              <i class="fa-solid fa-clipboard-list"></i>
              <span className="links_name">To Do</span>
            </a>
          </li>
          )}

          {(flag == 2 || flag == 3 || flag == 4) && (
          <li>
            <a 
            href="/#/ToDoAllAdmin"
             style={{ backgroundColor: "transparent" }}>
              <i class="fa-solid fa-clipboard-list"></i>
              <span className="links_name">Task To Do</span>
            </a>
          </li>
          )}

          {(flag == 1 || flag == 3 || flag == 2) && (
          <li>
            <a 
            href="/#/DonationList"
             style={{ backgroundColor: "transparent" }}>
              <i class="fa-solid fa-hand-holding-dollar"></i>
              <span className="links_name">Donation List</span>
            </a>
          </li>
          )}

          {(flag == 1 || flag == 3 || flag == 2) && (
          <li>
            <a 
            href="/#/AluminiList"
             style={{ backgroundColor: "transparent" }}>
           <i class="fa-solid fa-users"></i>
              <span className="links_name">Alumini List</span>
            </a>
          </li>
          )}

          {(flag == 1 || flag == 3 || flag == 2) && (
          <li>
            <a 
            href="/#/SuggestionAll"
             style={{ backgroundColor: "transparent" }}>
               <i class="fa-regular fa-file"></i>
              <span className="links_name">Suggestions</span>
            </a>
          </li>
          )}

          {(flag == 1 || flag == 2) && (
          <li>
            <a 
            href="/#/Discharge"
             style={{ backgroundColor: "transparent" }}>
                <i class="fa-solid fa-eject"></i>
              <span className="links_name">Discharge</span>
            </a>
          </li>
          )}

          {(flag == 1 || flag == 3 || flag == 2) && (
          <li>
            <a 
            href="/#/AdminDashboardReport"
             style={{ backgroundColor: "transparent" }}>
              <i class="fa-solid fa-border-all"></i>
              <span className="links_name">Report</span>
            </a>
          </li>
          )}

          {(flag == 1 || flag == 3) && (
          <li>
            <a 
            href="/#/Admin2Discharge"
             style={{ backgroundColor: "transparent" }}>
        <i class="fa-solid fa-user"></i>
              <span className="links_name">Discharge(Office Att.) </span>
            </a>
          </li>
          )}


        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
