import React, { useState, useEffect } from 'react';
import Sidebar from '../sidebar';
import NavLogo from '../NavLogo';
import { MDBDataTable } from 'mdbreact';
import { SuggestionAllFindAll } from '../../ApiFile';
import { Col, Row } from 'react-bootstrap';

const SuggestionAll = () => {
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const response = await fetch(SuggestionAllFindAll);
        if (response.ok) {
          const data = await response.json();
          setSuggestions(data);
        } else {
          console.error('Error fetching suggestions: Response not ok');
        }
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    };

    fetchSuggestions();
  }, []);

  const tableData = {
    columns: [
      {
        label: 'Sr.No',
        field: 'sr_no',
        sort: 'asc',
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
      },
     
      {
        label: 'Suggestion Date',
        field: 'suggestion_date',
        sort: 'asc',
      },
      {
        label: 'Suggestion By',
        field: 'suggestionBy',
        sort: 'asc',
      },
      {
        label: 'Suggestion Description',
        field: 'suggestion_discription',
        sort: 'asc',
      },
    ],
    rows: suggestions.map((suggestion, index) => ({
        sr_no:index + 1,
      name: suggestion.name,
      suggestion_date: suggestion.suggestion_date,
      suggestionBy: suggestion.suggestionBy,
      suggestion_discription: suggestion.suggestion_discription,
    })),
  };

  return (
    <>
      <Sidebar />
      <section className="home-section">
        <NavLogo />
      
        <div className="block-header">
          <h2 className="text-center mt-md-4 mt-2 m-0 p-0" style={{ color: 'maroon' }}>
            Suggestion All
          </h2>
        </div>

        
        <Row className="m-0 p-0 px-md-3 py-1 pb-5 mb-5 mt-4 w-100" style={{ display: 'flex' }}>
          <Col className="m-0 p-0 px-3 py-3 rounded" style={{ background: 'white' }}>
            <div style={{ overflowY: 'auto' }}>
              <div style={{ overflowX: 'auto' }}>
                <MDBDataTable striped className="custom-datatable text-center" bordered hover small data={tableData} />
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default SuggestionAll;
