import React, { useState, useEffect } from "react";
import Sidebar from "../sidebar";
import NavLogo from "../NavLogo";
import { Input, Modal, notification, Table } from "antd";
import { Button } from "@mui/material";
import {
  AdminDischargeGetData,
  AdminDischargeStudentList,
  AdminDischargeStudentPersonalDetails,
  AdminSideDischargeAttendance,
  AdminSideDischargeAttendanceEventDetail,
  AdminSideDischargeFeeDetails,
  AdminSideDischargeStudent,
  ImagePath,
} from "../../ApiFile";
import { Select } from "antd";
import { MDBDataTable } from "mdbreact";
import { Col, Row } from "react-bootstrap";
import axios from "axios";

const Discharge = () => {
  const [students, setStudents] = useState([]);
  const [studentsList, setStudentsList] = useState([]);
  const [data, setData] = useState(null);
  const [feeDetails, setFeeDetails] = useState(null);
  const [refund_amount, setrefund_amount] = useState('');
  const [fine_amount, setfine_amount] = useState('');
  const [remark, setremark] = useState('');
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [studentData, setStudentData] = useState(null);
  const [modalData, setModalData] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const sessionYears = sessionStorage.getItem("selectedYear");

  useEffect(() => {
    fetch(`${AdminDischargeStudentList}/${sessionYears}`)
      .then((response) => response.json())
      .then((data) => {
        setStudentsList(data);
      })
      .catch((error) => console.error("Error fetching student list:", error));
  }, [sessionYears]);

  useEffect(() => {
    if (selectedStudent) {
      fetch(
        `${AdminDischargeStudentPersonalDetails}/${selectedStudent}/${sessionYears}`
      )
        .then((response) => response.json())
        .then((data) => {
          setStudentData(data);
        })
        .catch((error) =>
          console.error("Error fetching student personal details:", error)
        );
    }
  }, [selectedStudent, sessionYears]);

  useEffect(() => {
    if (selectedStudent) {
      axios
        .get(
          `${AdminSideDischargeFeeDetails}/${selectedStudent}/${sessionYears}`
        )
        .then((response) => {
          setFeeDetails(response.data[0]);
        })
        .catch((error) => {
          console.error("There was an error fetching the fee details!", error);
        });
    }
  }, [selectedStudent, sessionYears]);

  useEffect(() => {
    fetch(`${AdminDischargeGetData}/${sessionYears}`)
      .then((response) => response.json())
      .then((data) => {
        // Add serial number to each student object
        const studentsWithSrNo = data.map((student, index) => ({
          ...student,
          srno: index + 1, // Adding serial number starting from 1
        }));
        setStudents(studentsWithSrNo);
      })
      .catch((error) => console.error("Error fetching discharge data:", error));
  }, [sessionYears]);

  useEffect(() => {
    if (selectedStudent) {
      axios
        .get(
          `${AdminSideDischargeAttendance}/${selectedStudent}/${sessionYears}`
        )
        .then((response) => {
          if (response.data === "NOTFOUND") {
            throw new Error("Attendance data not found");
          }
          setData(response.data[0]);
        })
        .catch((error) => {
          console.error(
            "There was an error fetching the attendance data!",
            error.message
          );
          // You can set a state here to show a message in the UI
          setData(null); // or handle it according to your needs
        });
    }
  }, [selectedStudent, sessionYears]);


  const handleSubmit = () => {
    if (!selectedStudent) {
      notification.warning({ message: 'Please select a student.' });
      return;
    }
    
    const dischargeData = {
      member_id: selectedStudent,
      boarding_year: sessionYears,
      refund_amount: refund_amount,
      fine_amount: fine_amount,
      remark: remark,
    };

    axios.post(AdminSideDischargeStudent, dischargeData)
      .then(response => {
        notification.success({ message: 'Discharge processed successfully.' });
        setrefund_amount('');
        setfine_amount('');
        setremark('');
        setSelectedStudent(null);
        // window.location.reload();
      })
      .catch(error => {
        console.error('Error processing discharge:', error);
        notification.error({ message: 'Failed to process discharge.' });
      });
  };

  const showModal = (title, attendanceFlag) => {
    axios
      .get(`${AdminSideDischargeAttendanceEventDetail}/${selectedStudent}`)
      .then((response) => {
        const eventsData = response.data.events;
        let eventDetails = [];

        if (attendanceFlag == 1) {
          // For present events
          eventDetails = eventsData.present;
        } else if (attendanceFlag == 2) {
          // For absent events
          eventDetails = eventsData.absent;
        }

        setModalData(eventDetails);
        setModalTitle(title);
        setIsModalVisible(true);
      })
      .catch((error) => {
        console.error("There was an error fetching the event details!", error);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setModalData([]);
  };

  const handleRefundAmount = (fineAmount) => {
    if (feeDetails) {
      const hostelDeposit = parseFloat(feeDetails.Hostel_deposit) || 0;
      setrefund_amount(hostelDeposit - (parseFloat(fineAmount) || 0));
    }
  };

  const handleFineAmountChange = (e) => {
    setfine_amount(e.target.value);
    handleRefundAmount(e.target.value);
  };

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "serial_no",
      key: "serial_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Event Name",
      dataIndex: "discription",
      key: "discription",
    },
    {
      title: "Event Date",
      dataIndex: "event_date",
      key: "event_date",
    },
  ];

  const handleSelectChange = (value) => {
    setSelectedStudent(value);
  };

  const studentOptions = studentsList.map((student) => ({
    value: student.member_id,
    label: student.name,
  }));

  const data1 = {
    columns: [
      {
        label: "Sr.No",
        field: "srno",
        sort: "asc",
        width: 50,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mobile Number",
        field: "mob_no",
        sort: "asc",
        width: 100,
      },
      {
        label: "Block No",
        field: "block_id",
        sort: "asc",
        width: 50,
      },
      {
        label: "Room No",
        field: "room_id",
        sort: "asc",
        width: 50,
      },
      {
        label: "Request Date",
        field: "request_dischargedate",
        sort: "asc",
        width: 100,
      },
      {
        label: "Discharge Reason",
        field: "discharge_reason",
        sort: "asc",
        width: 200,
      },
    ],
    rows: students,
  };

  return (
    <>
      <Sidebar />
      <section className="home-section pb-5">
        <NavLogo />

        <div className="member-list pb-5">
          <div className="row m-0 p-0 py-2">
            <div
              className="col-12 py-1 mb-3 shadow"
              style={{
                backgroundColor: "white",
                color: "maroon",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <span style={{ color: "maroon", fontSize: "18px" }}>
                  Discharge Student Data (admin-1)
                </span>
              </div>
            </div>

            <div className="col-12 pt-2 pt-md-0">
              <div className="row mb-3">
                <div className="col-md-4"></div>
                <div className="col-12 col-md-4">
                  <label style={{ fontWeight: "normal", fontSize: "13px" }}>
                    Select Student :
                  </label>
                  <Select
                    showSearch
                    className="w-100"
                    placeholder="Select Student"
                    optionFilterProp="label"
                    options={studentOptions}
                    onChange={handleSelectChange}
                    value={selectedStudent}
                  />
                </div>
                <div className="col-md-4"></div>
              </div>

              <Row className="m-0 p-0 pt-1 mb-2 mt-3 rounded">
                <Col
                  className="m-0 p-0 px-2 py-3 rounded"
                  style={{ background: "white" }}
                >
                  <div style={{ overflowY: "auto" }}>
                    <div style={{ overflowX: "auto" }}>
              <h5 style={{color:"maroon"}}>Discharge Request Student List</h5>
                      <MDBDataTable
                        style={{ backgroundColor: "white" }}
                        striped
                        className="custom-datatable"
                        bordered
                        responsive
                        small
                        data={data1}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          {studentData && (
            <div
              className="row m-0 p-2 mx-md-3 mb-2 rounded"
              style={{ backgroundColor: "whitesmoke" }}
            >
              <div
                className="col-12 py-1"
                style={{ backgroundColor: "#9acac9", borderRadius: "3px" }}
              >
                <span>Personal Details -</span>
              </div>
              <div className="col-12 col-md-5  pt-2">
                <label
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  Name:
                </label>
                <span>{studentData.name}</span>
              </div>
              <div className="col-12 col-md-2  pt-2">
                <label
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  Block No :
                </label>
                <span>{studentData.block_id}</span>
              </div>
              <div className="col-12 col-md-2  pt-2">
                <label
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  Room No :
                </label>
                <span>{studentData.room_id}</span>
              </div>

              <div className="col-12 col-md-2 text-center  pt-2">
                <label
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  Photo:
                </label>
                <span>
                  <img
                    src={`${ImagePath}/${studentData.image}`}
                    alt="img"
                    style={{ width: "100px", height: "100px" }}
                  />
                </span>
              </div>

              <div className="col-12 pt-2">
                <label
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  Address:
                </label>
                <span>{studentData.address}</span>
              </div>

              <div className="col-12 col-md-6  pt-2">
                <label
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  College Name:
                </label>
                <span>{studentData.collage_name}</span>
              </div>

              <div className="col-12 col-md-6  pt-2">
                <label
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  Standard :
                </label>
                <span>{studentData.std}</span>
              </div>

              <div className="col-12 col-md-6  pt-2">
                <label
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  Student's Contact No:
                </label>
                <span>{studentData.mob_no}</span>
              </div>
              <div className="col-12 col-md-6  pt-2">
                <label
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  Parent's Contact No:
                </label>
                <span>{studentData.parents_mobno}</span>
              </div>
            </div>
          )}

          {feeDetails && (
            <div
              className="row m-0 p-2 mx-md-3 mx-1 mb-1"
              style={{ backgroundColor: "whitesmoke" }}
            >
              <div
                className="col-12 py-1"
                style={{ backgroundColor: "#9acac9", borderRadius: "3px" }}
              >
                <span>Fee Details - </span>
              </div>
              <div className="col-12 col-md-6 col-lg-6 pt-2 d-flex">
                <label
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  Total Fee:
                </label>
                &nbsp;&nbsp;&nbsp;
                <span>{feeDetails.total_fee}</span>
              </div>

              <div className="col-12 col-md-6 col-lg-6 pt-2 d-flex">
                <label
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  Pending Fee:
                </label>
                &nbsp;&nbsp;&nbsp;
                <span>{feeDetails.pending_amount}</span>
              </div>

              {feeDetails.first_installment.length > 0 && (
                <div className="col-12 col-md-4 col-lg-4 pt-2 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    First Installment:{" "}
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>
                    {feeDetails.first_installment.map((installment) => (
                      <div key={installment.payment_date}>
                        {installment.total_amount} - {installment.payment_date}
                      </div>
                    ))}
                  </span>
                </div>
              )}
              {feeDetails.second_installment.length > 0 && (
                <div className="col-12 col-md-4 col-lg-4 pt-2 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    Second Installment:{" "}
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>
                    {feeDetails.second_installment.map((installment) => (
                      <div key={installment.payment_date}>
                        {installment.total_amount} - {installment.payment_date}
                      </div>
                    ))}
                  </span>
                </div>
              )}

              {feeDetails.without_installment.length > 0 && (
                <div className="col-12 col-md-4 col-lg-4 pt-2 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    Onetime Payment :{" "}
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>
                    {feeDetails.without_installment.map((installment) => (
                      <div key={installment.payment_date}>
                        {installment.total_amount} - {installment.payment_date}
                      </div>
                    ))}
                  </span>
                </div>
              )}
            </div>
          )}

          {data && (
            <>
              <div
                className="row m-0 p-2 mx-md-3 rounded mx-1 mb-1"
                style={{ backgroundColor: "whitesmoke" }}
              >
                <div
                  className="col-12 py-1"
                  style={{ backgroundColor: "#9acac9", borderRadius: "3px" }}
                >
                  <span>Regular Attendance - </span>
                </div>
                <div className="col-12 col-md-6 col-lg-4 pt-2 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    Absent:
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <span>{data.regularCount}</span>
                </div>
              </div>

              <div
                className="row m-0 mx-md-3 rounded p-2 mx-1 mb-1"
                style={{ backgroundColor: "whitesmoke" }}
              >
                <div
                  className="col-12 py-1"
                  style={{ backgroundColor: "#9acac9", borderRadius: "3px" }}
                >
                  <span>Event Attendance - </span>
                </div>

                <div className="col-12 col-md-6 col-lg-4 pt-2 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    Total Events:
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <span>
                    {data.eventCount.total_event}
                  </span>
                </div>

                <div className="col-12 col-md-6 col-lg-4 pt-2 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    Present:
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <span>{data.eventCount.present_eventCount}</span>{" "}
                  &nbsp;&nbsp;&nbsp;
                  <span>
                    {" "}
                    <Button
                      style={{ backgroundColor: "#9acac9", color: "black" }}
                      className="p-0 m-0"
                      onClick={() => showModal("Present Event Details", 1)} // Passing 1 for present events
                    >
                      View
                    </Button>
                  </span>
                </div>

                <div className="col-12 col-md-6 col-lg-4 pt-2 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    Absent:
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <span>{data.eventCount.absent_eventCount}</span>{" "}
                  &nbsp;&nbsp;&nbsp;
                  <span>
                    <Button
                      style={{ backgroundColor: "#9acac9", color: "black" }}
                      className="p-0 m-0"
                      onClick={() => showModal("Absent Event Details", 2)} // Passing 2 for absent events
                    >
                      View
                    </Button>
                  </span>
                </div>
              </div>

              <div
                className="row m-0 p-2 mx-md-3 rounded mx-1 mb-1"
                style={{ backgroundColor: "whitesmoke" }}
              >
                <div
                  className="col-12 py-1"
                  style={{ backgroundColor: "#9acac9", borderRadius: "3px" }}
                >
                  <span>Mess Attendance - </span>
                </div>
                <li>Lunch - </li>

                <div className="col-12 col-md-6 col-lg-4 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    Total Lunchs :
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <span>
                    {data.lunchCount.lunch_present +
                      data.lunchCount.lunch_absent}
                  </span>
                </div>

                <div className="col-12 col-md-6 col-lg-4 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    Present:
                  </label>
                  &nbsp;&nbsp;&nbsp;<span>{data.lunchCount.lunch_present}</span>
                </div>

                <div className="col-12 col-md-6 col-lg-4 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    Absent:
                  </label>
                  &nbsp;&nbsp;&nbsp;<span>{data.lunchCount.lunch_absent}</span>
                </div>

                <li>Dinner - </li>

                <div className="col-12 col-md-6 col-lg-4 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    Total Dinners :
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <span>
                    {data.dinnerCount.dinner_present +
                      data.dinnerCount.dinner_absent}
                  </span>
                </div>

                <div className="col-12 col-md-6 col-lg-4 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    Present:
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <span>{data.dinnerCount.dinner_present}</span>
                </div>

                <div className="col-12 col-md-6 col-lg-4 d-flex">
                  <label
                    style={{
                      fontWeight: "normal",
                      fontSize: "13px",
                      color: "grey",
                    }}
                  >
                    Absent:
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <span>{data.dinnerCount.dinner_absent}</span>
                </div>
              </div>
            </>
          )}

          {feeDetails && (
          <div
            className="row m-0 mx-md-3 rounded p-2 mx-1 mb-1"
            style={{ backgroundColor: "whitesmoke" }}
          >
            <div className="row mt-3">
              <style>
                {`
          .custom-input::placeholder {
            color: black;
            font-size:14px;
          }
        `}
              </style>
              <div className="col-12 col-md-3"> </div>
              <div className="col-12 col-md-4">
                <span
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  Deposit Amount :
                </span>
              </div>
              <div className="col-12 col-md-2 d-flex">
                {feeDetails && (
                  <>
                    <Input
                      readOnly
                      type="number"
                      placeholder={feeDetails.Hostel_deposit}
                      className="custom-input text-end"
                    />
                    &nbsp;<small className="pt-1"> Rs</small>
                  </>
                )}
              </div>
              <div className="col-12 col-md-3"> </div>
            </div>

            <div className="row mt-3">
              <div className="col-12 col-md-3"> </div>
              <div className="col-12 col-md-4">
                <span
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  Donation/Fine amount to be deducted :
                </span>
              </div>
              <div className="col-12 col-md-2 d-flex">
                <Input className="text-end"
                value={fine_amount}
                onChange={handleFineAmountChange}
                 type="number" maxLength={6} />{" "}
                &nbsp;<small className="pt-1"> Rs</small>
              </div>
              <div className="col-12 col-md-3"> </div>
            </div>

            <div className="row mt-3">
              <div className="col-12 col-md-3"> </div>
              <div className="col-12 col-md-4">
                <span
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  Total amount to be return :
                </span>
              </div>
              <div className="col-12 col-md-2 d-flex">
                <Input
                  type="number"
                  value={refund_amount}
                  onChange={handleRefundAmount}
                  readOnly
                  className="text-end"
                />
                &nbsp;<small className="pt-1"> Rs</small>
              </div>
              <div className="col-12 col-md-3"> </div>
            </div>

            <div className="row mt-3">
              <div className="col-12 col-md-3"> </div>
              <div className="col-12 col-md-4">
                <span
                  style={{
                    fontWeight: "normal",
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  Supertendent Remark :
                </span>
              </div>
              <div className="col-12 col-md-2 d-flex">
                <Input maxLength={200} value={remark}
                onChange={(e) => setremark(e.target.value)} type="text" />
                &nbsp;<small className="pt-1 text-light"> txt</small>
              </div>
              <div className="col-12 col-md-3"> </div>
            </div>

            <div className="text-center mt-4 mb-3">
              <Button
                style={{ backgroundColor: "#9acac9", color: "black" }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        )}

          <Modal
            title={modalTitle}
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={null}
          >
            <Table
              dataSource={modalData}
              columns={columns}
              rowKey="event_date"
              pagination={false}
            />
          </Modal>
        </div>
      </section>
    </>
  );
};

export default Discharge;
