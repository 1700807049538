// export const BaseIp = "http://192.168.29.112:8081/"




// New path ==================================
export const BaseIp = 'https://jainboardingsangli.com/';

// ============================== Admin Side Api =====================================

export const AdminDashboard = BaseIp + "api/dashboard/dashboard-data"
export const AluminiViewApi = BaseIp + "api/alumini"
export const BoardingYearApi = BaseIp + "api/admin/boarding-year"
export const AdminLoginApi = BaseIp + "api/admin/login"
export const BlockFindallApi = BaseIp + "api/block/find"
export const BedFindAllApi = BaseIp + "api/bed/find"
export const RoomFindAllApi = BaseIp + "api/room/find"
export const BedAddApi = BaseIp + "api/bed/add"
export const FeeTypeFindAllApi = BaseIp + "api/fee_type/findall"
export const BlockAddApi = BaseIp + "api/block/add"
export const BlockUpdateApi = BaseIp + "api/block/update"
export const RoomAddApi = BaseIp + "api/room/add"
export const RuleMasterFindallApi = BaseIp + "api/term/findAll"
export const HighlightMasterAdd = BaseIp + "api/admin_text/add"
export const HighlightMasterFindAll = BaseIp + "api/admin_text/findAll"
export const HighlightMasterUpdate = BaseIp + "api/admin_text/update"
export const HighlightMasterDelete = BaseIp + "api/admin_text/delete"
export const RuleMasterUpdateApi = BaseIp + "api/term/update"
export const RuleMasterAddApi = BaseIp + "api/term/add"
export const RuleMasterDeleteApi = BaseIp + "api/term/delete"
export const ChangePasswordAdminApi = BaseIp + "api/admin/change-password"  
export const AluminiGalleryMasterUpdateApi = BaseIp + "api/aluminigallery/update"  
export const AluminiGalleryMasterFindAllApi = BaseIp + "api/aluminigallery/findAll"  
export const AluminiGalleryMasterAddApi = BaseIp + "api/aluminigallery/add"  
export const AluminiGalleryMasterDeleteApi = BaseIp + "api/aluminigallery/delete"  
export const JobApprovalPendingApi = BaseIp + "api/admin/jobApproval-pending"  
export const JobPostApi = BaseIp + "api/jobpost/updateStatus"  
export const JobPostFlagApi = BaseIp + "api/jobpost/updateFlag"  
export const JobPostFindAllApi = BaseIp + "api/jobpost/find"  
export const JobPostDeleteApi = BaseIp + "api/jobpost/delete"  
export const FeeMasterFindAllApi = BaseIp + "api/feemaster/find"  
export const FeeMasterAddApi = BaseIp + "api/feemaster/add"  
export const FeeMasterUpdateApi = BaseIp + "api/feemaster/update"  
export const ComplaintFindAllApi = BaseIp + "api/comapliant/findAll"  
export const ComplaintFindOneApi = BaseIp + "api/comapliant/find"  
export const ComplaintUpdateStatusApi = BaseIp + "api/comapliant/admin-update"  
export const ComplaintUpdateApi = BaseIp + "api/comapliant/admin-update"  
export const ComplaintDeleteApi = BaseIp + "api/comapliant/delete"  
export const EventAddApi = BaseIp + "api/event/add"  
export const EventFindApi = BaseIp + "api/event/find"  
export const EventUpdateApi = BaseIp + "api/event/update"  
export const EventDeleteApi = BaseIp + "api/event/delete"  
export const FeeTypeFindAllApi1 = BaseIp + "api/fee_type/findall"  
export const FeemasterUpdate = BaseIp + "api/feemaster/update" 
export const FeeMasterAdd = BaseIp + "api/feemaster/add"  
export const FeeMasterFindAll = BaseIp + "api/feemaster/find"  
export const FeeMasterDelete = BaseIp + ""  
export const FeeTypeUpdateApi = BaseIp + "api/fee_type/update"  
export const FeeTypeAddApi = BaseIp + "api/fee_type/add"  
export const CategoryFindAllApi = BaseIp + "api/category/findAll"  
export const CategoryJobUpdateApi = BaseIp + "api/category/update"  
export const CategoryJobDeleteApi = BaseIp + "api/category/delete"  
export const CategoryAddApi = BaseIp + "api/category/add"  
export const JobPostAddApi = BaseIp + "api/jobpost/add"  
export const JobPostAdminFindOneApi = BaseIp + "api/jobpost/admin-findOne"  
export const JobPostFindAllApi1 = BaseIp + "api/category/findAll"  
export const JobPostAdminFindAll = BaseIp + "api/jobpost/admin-findAll"  
export const JobPostAdminDelete = BaseIp + "api/jobpost/delete"  
export const JobPostAdminUpdate = BaseIp + "api/jobpost/update"  
export const JobPostFindAllApi2 = BaseIp + "api/jobpost/findall"  
export const JobPostUpdateApi = BaseIp + "api/jobpost/update"  
export const PaymentFindAllApi = BaseIp + "api/payment/findAll"  
export const PaymentApprovalApi = BaseIp + "api/admin/admin-paymentapproval"  
export const MemberApprovalApi = BaseIp + "api/admin/approval-list"  
export const JobApprovalFindallApi = BaseIp + "api/jobpost/admin-findAll"  
export const JobApprovalFinalList = BaseIp + "api/jobpost/admin-jobapprovallist"  
export const JobApprovalFinalListFindOne = BaseIp + "api/jobpost/admin-jobapprovalfindOne"  
export const JobPostFindAll = BaseIp + "api/jobpost/findAll"  
export const JobpostApprovalView = BaseIp + "api/jobpost/approval-view"  
export const JobPostApprovalReject = BaseIp + "api/admin/admin-jobapproval"  
export const MemberAdminApprovalApi = BaseIp + "api/admin/approval-admin"  
export const MemberAdminViewApi = BaseIp + "api/admin/view"  
export const RoomApprovalPendingApi = BaseIp + "api/admin/admin-pendingroomapproval"  
export const RoomApprovalViewgApi = BaseIp + "api/admin/roomApproval-view"  
export const RoomApprovalApi = BaseIp + "api/admin/admin-roomapproval"  
export const AllocateFindAllApi = BaseIp + "api/allocate/find"  
export const StudentApprovalListApi = BaseIp + "api/admin/approval-studentlist"  
export const StudentApprovalApi = BaseIp + "api/admin/admin-approval"  
export const StudentAFindOneApi = BaseIp + "api/document/findOne"  
export const DashboardComplaintApi = BaseIp + "api/comapliant/findAll"  
export const DashboardRegistrationFindAllApi = BaseIp + "api/registration/findAll"  
export const DashboardPaymentFindAllApi = BaseIp + "api/payment/findAll"  
export const BedBookListApi = BaseIp + "api/allocate/bed-booklist"  
export const BedAllocateAddApi = BaseIp + "api/allocate/add"  
export const BedAllocateAddApi2 = BaseIp + "api/allocate/select-room"  
export const EventDashboardApi = BaseIp + "api/dashboard/recent-eventlist"  
export const ReceiptFeeDetailsApi = BaseIp + "api/receipt/fee-details"  
export const RoomChangeListApi = BaseIp + "api/roomchange/student-list"  
export const AdminRoomChangePaymentApi = BaseIp + "api/payment/roomchange-payment"  
export const AdminRoomChangeAddApi = BaseIp + "api/roomchange/add"  
export const AdminRoomChangeRefundApi = BaseIp + "api/refund_hostelfee/add"  
export const AdminPaymentApprovalFindOneApi = BaseIp + "api/payment/find"  
export const AdminWebsiteFirstImageApi = BaseIp + "api/mainpage/add"  
export const AdminWebsiteFirstImageUpdateApi = BaseIp + "api/mainpage/update"  
export const AdminWebsiteFirstImageDeleteApi = BaseIp + "api/mainpage/delete"  
export const AdminWebsiteFirstImageFindAllApi = BaseIp + "api/mainpage/findAll"  
export const GalleryAddApi = BaseIp + "api/photogallery/add"  
export const GalleryFindAllApi = BaseIp + "api/photogallery/findAll"  
export const GalleryUpdateApi = BaseIp + "api/photogallery/update"  
export const GalleryDeleteApi = BaseIp + "api/photogallery/delete"  
export const DirectorAddApi = BaseIp + "api/directophoto/add"  
export const DirectorFindAllApi = BaseIp + "api/directophoto/findAll"  
export const DirectorUpdateApi = BaseIp + "api/directophoto/update"  
export const DirectorDeleteApi = BaseIp + "api/directophoto/delete" 
export const EventAttendanceAdd= BaseIp + "api/event_attendance/add" 
export const EventAttendanceUpdate= BaseIp + "api/event_attendance/event-attendanceupdate" 
export const EventDateList= BaseIp + "api/event/event-datelist"
export const EventDateSelectAttendance= BaseIp + "api/event/find"
export const EventAluminiSuggestionAdd= BaseIp + "api/suggestion/add"
export const EventAluminiHomeUpdate= BaseIp + "api/alumini/update"
export const EventAluminiFindOne= BaseIp + "api/alumini/findOne"
export const EventAluminiGalleryFindAll= BaseIp + "api/photogallery/findAll"
export const SuggestionAllFindAll= BaseIp + "api/suggestion/admin-suggestionfindAll"
export const AluminiStateFindAll= BaseIp + "api/state/findAll"
export const AluminiDistrictFind= BaseIp + "api/district/find"
export const AluminiTalukaFind= BaseIp + "api/taluka/find"
export const AluminiAdd= BaseIp + "api/alumini/add"
export const EventMemberList= BaseIp + "api/event/event-memberlist"
export const EventLeaveList= BaseIp + "api/leave-event/event-list"
export const MessLeaveFindall= BaseIp + "api/leave-mess/findAll"
export const MessLeaveAdd= BaseIp + "api/leave-mess/add"
export const MessLeaveCancel= BaseIp + "api/leave-mess/cancel"
export const MessLeaveUpdate= BaseIp + "api/leave-mess/update"
export const MessLeaveUpdateFindone= BaseIp + "api/leave-mess/updatefindAll"
export const EventLeaveFindAll= BaseIp + "api/leave-event/findAll"
export const EventLeaveAdd= BaseIp + "api/leave-event/add"
export const EventLeaveDelete= BaseIp + "api/leave-event/cancel"
export const EventLeaveFindOne= BaseIp + "api/adminapproval/event-approvalfindOne"
export const EventLeaveUpdate= BaseIp + "api/leave-event/update"
export const RegularLeaveAdd= BaseIp + "api/leave_regular/add"
export const RegularLeaveFindall= BaseIp + "api/leave_regular/findAll"
export const RegularLeaveUpdate= BaseIp + "api/leave_regular/update"
export const RegularLeaveDelete= BaseIp + "api/leave_regular/cancel"
export const ApproveRegularLeaveFindall= BaseIp + "api/adminapproval/regular-findAll"
export const ApproveRegularLeavestatus= BaseIp + "api/adminapproval/regular-leaveapproval"
export const ApproveRegularLeaveFindone= BaseIp + "api/adminapproval/regular-findOne"
export const MessLeaveUpdateFindall= BaseIp + "api/leave-mess/updatefindAll"
export const EventLeaveAdminApprovalFindall= BaseIp + "api/adminapproval/eventleave-adminapprovallist"
export const EventLeaveAdminApprovalReject= BaseIp + "api/adminapproval/admin-eventleaveapproval"
export const AdminMessLeaveApproveFindAll= BaseIp + "api/adminapproval/mess-findAll"
export const AdminMessLeaveApproveReject= BaseIp + "api/adminapproval/mess-leaveapproval"
export const AdminMessLeaveApproveFindOne= BaseIp + "api/adminapproval/mess-approvalfindOne"
export const AdminMessLeaveApproveLeaveList= BaseIp + "api/adminapproval/leave-list"
export const AdminToDoAdd= BaseIp + "api/admintodo/add"
export const AdminToDoFindAll= BaseIp + "api/admintodo/findAll"
export const DonationFindAll= BaseIp + "api/donation/findAll"
export const AluminiFindAll= BaseIp + "api/alumini/findAll"
export const AluminiFindOne= BaseIp + "api/alumini/findOne"
export const ToDoFindOne= BaseIp + "api/admintodo/findOne"


// REPORT===================================================================
export const ReportMemberListApi = BaseIp + "api/dashboard/reg-memberlist"  
export const ReportDashboard = BaseIp + "api/report/report-dashboard"  
export const ReportBookVacantList = BaseIp + "api/report/book-vaccantlist"  
export const ReportAlphabeticalList = BaseIp + "api/report/alphabetical-list"  
export const ReportAluminiReport = BaseIp + "api/report/alumini-report"  
export const ReportBlockWiseList = BaseIp + "api/report/blockwise-list"  
export const ReportForwardDeposit = BaseIp + "api/report/carryforword-deposit"  
export const ReportStudentList = BaseIp + "api/report/studentlist"  
export const DischargeStudent = BaseIp + "api/students"  
export const DischargeStudentSubmit = BaseIp + "api/students/submit"  
export const DischargeReportReport = BaseIp + "api/report/discharge-report"  
export const ReportEventList = BaseIp + "api/report/report-eventlist"  
export const ReportDateWiseEventList = BaseIp + "api/report/datewise-eventlist"  
export const ReportEvent = BaseIp + "api/report/eventlist-report"  
export const ReporStudentWiseEventList = BaseIp + "api/report/studentwise-eventlist"  
export const ReporFeeStatus = BaseIp + "api/report/fee-statuslist"  
export const ReporMessWiseAttendance = BaseIp + "api/report/messwise-attendance"  
export const ReporMessWiseAttendanceSingleStudent = BaseIp + "api/report/mess_singlestudent"  
export const ReportNewAdmit = BaseIp + "api/report/new-admit"  
export const ReportInstallmentPending = BaseIp + "api/report/installmentPending"  
export const ReportReAdmit = BaseIp + "api/report/re-admit"  
export const ReportLeaveRegularReport = BaseIp + "api/report/leave-regularreport"  
export const ReportEventlist = BaseIp + "api/report/report-eventlist"  
export const ReportEventLeaveReport = BaseIp + "api/report/leave-eventreport"  
export const ReportMessLeaveReport = BaseIp + "api/report/leave-messreport"  
export const ReportTodaysDeposit = BaseIp + "api/report/todays-deposit"  
export const ReportReturnDeposit = BaseIp + "api/report/today-returndeposit"  
export const ReportFeeReport = BaseIp + "api/report/fee-report"  
export const ReportTodaysRegFormPayment = BaseIp + "api/report/today-regformPayment"  
export const ReportTodaysRefundHostelFee = BaseIp + "api/report/todays-refundhostelfee"  
export const ReportRegFormPayment = BaseIp + "api/report/regformPayment"  
export const ReportTotalDeposit = BaseIp + "api/report/total-deposit"  
export const ReportTotalReturnDeposit = BaseIp + "api/report/total-returndeposit"  
export const ReportFeeTotalReport = BaseIp + "api/report/fee-totalreport"  
export const ReportTotalRefundHostelFee = BaseIp + "api/report/total-refundhostelfee"  
export const ReportVaccantList = BaseIp + "api/report/vaccant-list"  
export const ReportDonationList = BaseIp + "api/report/donation"  



// ====================== Room Booking Api ===================== 
export const MemberLoginApi = BaseIp + "api/member/login"  
export const BlockFindall = BaseIp + "api/allocate/block-data"
export const ChangePasswordApi = BaseIp + "api/member/change-password"
export const RoomBookingApi = BaseIp + "api/payment/payment-data"
export const RoomBookingAddApi = BaseIp + "api/payment/add"
export const RoomAllocateListApi = BaseIp + "api/allocate/room-list"
export const BlockFindAllApi = BaseIp + "api/block/find"



// ====================== Student Registration Form ===================
export const TermFindAllApi = BaseIp + "api/term/findAll"
export const StateFindAllApi = BaseIp + "api/state/findAll"
export const DistrictFindAllApi = BaseIp + "api/district/find"
export const TalukaFindAllApi = BaseIp + "api/taluka/find"
export const SubCateroryFindAllApi = BaseIp + "api/sub-category/findAll"
export const RegristrationAddApi = BaseIp + "api/registration/add"
export const DocumentAddApi = BaseIp + "api/document/add"
export const DocumentFindOneApi = BaseIp + "api/document/findOne"





// ======================= Student Dashboard ====================
export const StudentDownloadReceiptApi = BaseIp + "api/receipt/download-payReceipt"
export const StudentInstallmentPayApi = BaseIp + "api/dashboard/installment-paymentdata"
export const ReceiptIdApi = BaseIp + "api/receipt/receipt-id"
export const StudentComplaintFindOneApi = BaseIp + "api/comapliant/findone"
export const StudentComplaintAddApi = BaseIp + "api/comapliant/add"
export const StudentComplaintUpdateApi = BaseIp + "api/comapliant/student-update"
export const StudentEventAttendance = BaseIp + "api/studentdashboard/getdata-event"
export const StudentProfileApi = BaseIp + "api/dashboard/member-profile"
export const StudentDischargegetData= BaseIp + "api/student-dischagerequest/student-dischargedata"
export const StudentDischargeFeeDetails = BaseIp + "api/student-dischagerequest/dischage-feedetails"
export const StudentREquestDischarge = BaseIp + "api/student-dischagerequest/request-discharge"
export const StudentDischargeReqEventDetails = BaseIp + "api/student-dischagerequest/eventdetails"
export const AdminDischargeGetData = BaseIp + "api/admin-dischagestudent/student-dischargedata"
export const AdminDischargeStudentList = BaseIp + "api/admin-dischagestudent/student-list"
export const AdminDischargeStudentPersonalDetails = BaseIp + "api/admin-dischagestudent/presonaldetails"
export const AdminSideDischargeFeeDetails = BaseIp + "api/admin-dischagestudent/feedatils"
export const AdminSideDischargeAttendance = BaseIp + "api/admin-dischagestudent/attendance-count"
export const AdminSideDischargeAttendanceEventDetail = BaseIp + "api/admin-dischagestudent/attendance-details"
export const AdminSideDischargeStudent = BaseIp + "api/admin-dischagestudent/admin-discharge"
export const StudentSuggestionAdd = BaseIp + "api/studentsuggestion/add"
export const StudentSuggestionFindOne = BaseIp + "api/studentsuggestion/findOne"




// ======================= ADMIN 2 Discharge  ====================
export const Admin2SelectedStudentList = BaseIp + "api/admin-dischagestudent/finaldischarge-studentlist"
export const Admin2SelectedStudentData = BaseIp + "api/admin-dischagestudent/finaldischarge-student"
export const Admin2SelectedSubmit = BaseIp + "api/admin-dischagestudent/final-discharge"
export const Admin2GetReceiptId = BaseIp + "api/admin-dischagestudent/discharge-receitId"
export const Admin2Generatereceipt = BaseIp + "api/admin-dischagestudent/download-disschargereceipt"
export const AdminDownloadAllReceipt = BaseIp + "api/report/download-allrecipt"




// ======================= Alumini  ====================
export const AluminiSignUpApi = BaseIp + "api/aluminisignup/add"
export const AluminiLoginpApi = BaseIp + "api/aluminisignup/login"
export const AluminiJobpostAddApi = BaseIp + "api/jobpost/add"
export const AluminiJobpostFindAllApi = BaseIp + "api/jobpost/findAll"
export const AluminiJobpostFindoneApi = BaseIp + "api/jobpost/findOne"
export const AluminiJobpostUpdateApi = BaseIp + "api/jobpost/update"
export const AluminiSuggestionFindOne = BaseIp + "api/suggestion/findOne"
export const AluminiJobDelete = BaseIp + "api/jobpost/alumini_jobdelete"





// ======================= Website Design  ====================
export const CarouselImageApi = BaseIp + "api/mainpage/findAll"
export const DirectorPhotoApi = BaseIp + "api/directophoto/findAll"
export const GalleryApi = BaseIp + "api/photogallery/findAll"
export const RoomDetailsApi = BaseIp + "api/roomdetails/findAll"
export const DonationAdd = BaseIp + "api/donation/add"




// ======================= Mess  ====================

export const AdminBreakfastAddApi = BaseIp + "api/brakfast-menu/add"
export const AdminBreakfastFindAllApi = BaseIp + "api/brakfast-menu/findAll"
export const AdminBreakfastUpdateApi = BaseIp + "api/brakfast-menu/update"
export const AdminBreakfastDeleteApi = BaseIp + "api/brakfast-menu/delete"
export const AdminMenuMasterAdd = BaseIp + "api/mess_menumaster/add"
export const AdminMenuMasterFindAll = BaseIp + "api/mess_menumaster/findAll"
export const AdminMenuMasterUpdate = BaseIp + "api/mess_menumaster/update"
export const AdminMenuMasterDelete = BaseIp + "api/mess_menumaster/delete"
export const AdminMenuMasterDaysFindall = BaseIp + "api/mess_menumaster/day-find"
export const AdminMessFeedbackApi = BaseIp + "api/messfeedback/admin-findAll"
export const AdminBreakfastRecFindallApi = BaseIp + "api/brakfast-request/admin-findAll"
export const AdminBreakfastRecFApprovalApi = BaseIp + "api/brakfast-request/req_approval"
export const AdminMessAttendanceAdd = BaseIp + "api/mess-attendance/add"
export const AdminMessAttendanceMemberList = BaseIp + "api/mess-attendance/mess-memberlist"
export const StudentBreakfastReqList = BaseIp + "api/brakfast-request/memberfindAll"
export const StudentFeedbackAdd = BaseIp + "api/messfeedback/add"
export const StudentFeedbackUpdate = BaseIp + "api/messfeedback/update"
export const StudentFeedbackDelete = BaseIp + "api/messfeedback/delete"
export const StudentFeedbackFindall = BaseIp + "api/messfeedback/findAll"
export const StudentBreakfastReqAdd = BaseIp + "api/brakfast-request/add"
export const StudentBreakfastReqUpdate = BaseIp + "api/brakfast-request/memberupdatereq"
export const StudentBreakfastReqDelete = BaseIp + "api/brakfast-request/cancelreq"
export const StudentBreakfastReqFindAll = BaseIp + "api/brakfast-request/memberfindAll"
export const StudentMessAttendanceFindAll = BaseIp + "api/mess-attendance/student-attendance"
export const StudentMessTodaysMenuFindall = BaseIp + "api/dashboard/todaymenu"



// ========================== Image Path ==============================
export const ImagePath =  "https://jainboardingsangli.com/server"








// build path==============================================================================================================

// set NODE_OPTIONS=--max_old_space_size=4096
// npm run build
