import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FiCalendar } from 'react-icons/fi';
import { EventFindApi } from '../../ApiFile';



const MarqueeItem = ({ item }) => (
  <marquee style={{ marginRight: "20px", fontSize: "21px", display: "inline-block" }}>
    <span style={{ animation: "blinking 0.4s infinite", marginRight: "5px", color: "red" }}>🚨</span>
    <span style={{backgroundColor:'red',padding:'3px',borderRadius:'6px',fontSize:'16px',fontWeight:'600'}}>      
      <FiCalendar style={{verticalAlign: 'middle', marginRight: '5px',marginBottom:'5px'}}/>
      {item.event_date}
    </span> 
    <span style={{fontWeight: "bold"}}>{item.discription}</span>
  </marquee>
);

const Marquee = () => {
  const [newsAndEvents, setNewsAndEvents] = useState({ data: [], currentIndex: 0 });

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(EventFindApi);
        if (Array.isArray(response.data)) {
          setNewsAndEvents({ data: response.data, currentIndex: 0 });
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchEvents();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setNewsAndEvents((prevState) => {
        const nextIndex = (prevState.currentIndex + 1) % prevState.data.length;
        return { ...prevState, currentIndex: nextIndex };
      });
    }, 2000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div style={{ backgroundColor: 'blanchedalmond', overflow: "hidden", whiteSpace: "nowrap", position:'fixed', bottom:'0' }}>
      {newsAndEvents.data.map((item, index) => (
        <MarqueeItem key={index} item={item} />
      ))}
      <style jsx>{`
        @keyframes blinking {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
};

export default Marquee;
